import React, { useEffect, useState } from "react";
import routes from "../../routes";
import { Link } from 'react-router-dom';

import adminApis from "../../api/admin";
import useApi from "../../hooks/useApi";
import { Button, Modal } from "react-bootstrap";
import styles from "../../styles/TalentStyleAttribute.module.css";
import logo from "../../images/LogoParodyWhite.svg";
import NavItem from "../../components/NavItem";
import Wrapper from "../../layouts/Wrapper";
import { PuffLoader } from "react-spinners";
import { helpers } from "../../utils/helpers";
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Form, SubmitButton, TextField } from "../../components/form";
import schemas from "../../utils/schemas";

function AddStyleAttribute(props) {


    const [success, setSuccess] = useState(false);
    const [quadrant, setQuadrant] = useState({})
    const addQuadrantApi = useApi(adminApis.addQuadrant);

    const handleSubmit = async (e) => {
        e.preventDefault();
        addQuadrantApi.setLoading(true);

        const result = await addQuadrantApi.request(quadrant);
        if(result.ok) {
            helpers.toaster.success("Quadrant Created Successfully");
            window.location.reload(false);
        }

        //return navigate(routes.);
    }

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">

                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                <form method="post" onSubmit={handleSubmit} className={styles.form}>
                    <h4 align="left" className="mb-5">Add Style Attributes</h4>
                    <div className="row">
                        <div className="col-lg-6">
                            <label>Quadrant Title</label>
                            <input type="text" id="" className="form-control" placeholder="" onChange={(e) => setQuadrant({ ...quadrant, title: e.target.value })}></input>
                        </div>
                        <div className="col-lg-6">
                            <label>Sub Text</label>
                            <input type="text" id="" className="form-control" placeholder="" onChange={(e) => setQuadrant({ ...quadrant, subText: e.target.value })}></input>
                        </div>
                        <div className="col-lg-6">
                            <label>Primary Text</label>
                            <input type="text" id="" className="form-control" placeholder="" onChange={(e) => setQuadrant({ ...quadrant, primaryText: e.target.value })}></input>
                        </div>
                        <div className="col-lg-6">
                            <label>Secondary Text</label>
                            <input type="text" id="" className="form-control" placeholder="" onChange={(e) => setQuadrant({ ...quadrant, secondaryText: e.target.value })}></input>
                        </div>
                    </div>
                    <div className="text-center mt-5">
                        <button type="submit" className={styles.sendCode}>{addQuadrantApi.loading ? "Adding Quadrant..." : "Submit"}</button>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    );
}

const TalentStyleAttribute = () => {

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShow1, setModalShow1] = React.useState(false);

    const [quadrantToDelete, setQuadrantToDelete] = useState();

    const getQuadrantApi = useApi(adminApis.getQuadrant);
    const editQuadrantApi = useApi(adminApis.editQuadrant);
    const deleteQuadrantApi = useApi(adminApis.deleteQuadrant);
    const [quadrant, setQuadrant] = useState([]);
    const [editQuadrant, setEditQuadrant] = useState();

    useEffect(() => {
        getQuadrant();
    }, []);

    const getQuadrant = async () => {
        const res = await getQuadrantApi.request();
        if (res.ok) setQuadrant(res.data.data);
    }

    const [success, setSuccess] = useState(false);
    
    const handleEdit = async (data) => {

        const res = await editQuadrantApi.request({ ...data, code: editQuadrant.code });

        if (res.ok) {
            helpers.toaster.success("Quadrant Updated Successfully");
            window.location.reload(false);
        }
        editQuadrantApi.setLoading(false);

        //return navigate(routes.);
    }

    const handleDelete = async (code) => {
        setQuadrantToDelete(code);
        const del = window.confirm("Are you sure you wants to delete this Quadrant?");
        if (del) {
            const res = await deleteQuadrantApi.request(code);
            // if(!res.ok) return
            helpers.toaster.success("Success Deleted");
            window.location.reload(false);
        }
    }


    return (
        <Wrapper activePage={routes.TalentStyleAttribute}>
            <div className="row m-0 p-0">
                <div className="col-lg-7">
                    <h2 align="right">Styles Attributes </h2>
                </div>

                <div className="col-lg-5 text-end">
                    <button onClick={() => setModalShow(true)} className={styles.invite}>+&nbsp;&nbsp;&nbsp;&nbsp; ADD STYLES ATTRIBUTES</button>
                    <AddStyleAttribute
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </div>
            </div>    
            
            <div className="row m-0 p-0">
                <div className="col-lg-10 m-auto">
                    <div className={styles.card1}>
                        <table>
                            <tr style={{ borderBottom: "1px solid grey" }}>
                                <td className={styles.left}><span>Title</span></td>
                                <td className={styles.left}><span>Sub Text</span></td>
                                <td className={styles.left}><span>Primary Text</span></td>
                                <td className={styles.left}><span>Secondary Text</span></td>
                                <td className={styles.left}><span>Action</span></td>
                            </tr>
                            {quadrant.length === 0 && !getQuadrantApi.loading && <tr>No Quadrants available </tr>}
                            {getQuadrantApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                            quadrant.map((item, key) => <tr key={key} style={{borderBottom: "1px solid grey"}}>
                                <td className={styles.left}><label>{item.title}</label></td>
                                <td className={styles.left}><label>{item.subText}</label></td>
                                <td className={styles.left}><label>{item.primaryText}</label></td>
                                <td className={styles.left}><label>{item.secondaryText}</label></td>
                                <td className={styles.left}><button type="submit" onClick={() => setEditQuadrant(item)} className={styles.success}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" onClick={() => handleDelete(item.code)} className={styles.danger}> <FaTrash /> {quadrantToDelete === item.code && deleteQuadrantApi.loading ? "Deleting.." : "Delete"} </button></td>
                            </tr>)
                            }
                        </table>
                        <Modal
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            title="Send Code"
                            show={editQuadrant?.code}
                            onHide={() => setEditQuadrant(null)}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">

                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="text-center">
                                <Form
                                    enableReinitialize
                                    validationSchema={schemas.editQuadrantSchema}
                                    initialValues={{
                                        title: editQuadrant?.title,
                                        subText: editQuadrant?.subText,
                                        primaryText: editQuadrant?.primaryText,
                                        secondaryText: editQuadrant?.secondaryText,
                                    }} 
                                    onSubmit={handleEdit} className={styles.form}
                                >
                                    <h4 align="left" className="mb-5">Update Style Attributes</h4>
                                    <div className="row mx-4">
                                        <div className="col-lg-6">
                                            <TextField name={"title"} label={"Title"} className={styles.input} />
                                        </div>
                                        <div className="col-lg-6">
                                            <TextField name={"subText"} label={"Subtext"} className={styles.input} />
                                        </div>
                                        <div className="col-lg-6">
                                            <TextField name={"primaryText"} label={"Primary Text"} className={styles.input} />
                                        </div>
                                        <div className="col-lg-6">
                                            <TextField name={"secondaryText"} label={"Secondary Text"} className={styles.input} />
                                        </div>
                                    </div>
                                    <div className="text-center mt-5">
                                        <SubmitButton title={"SUBMIT"} className={styles.sendCode} />
                                    </div>
                                </Form>
                            </Modal.Body>                                
                        </Modal>
                    </div>
                </div>   
            </div>
        </Wrapper>
    )
};

export default TalentStyleAttribute;