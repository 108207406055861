import { Link } from 'react-router-dom';
import 'react-pro-sidebar/dist/css/styles.css';
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from 'react';

import { ProSidebar, SidebarHeader, SidebarFooter, SidebarContent, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import routes from '../routes';
import store from '../api/store';
import logo from "../images/LogoParodyWhite.svg";
import styles from "../styles/sidebar.module.css";
import { AssesorIcon, AssessmentIcon, DashboardIcon, GamesIcon, ProfileIcon, ReportIcon, RequestIcon, SignOutIcon, TalentSkillIcon, TalentStyleIcon } from '../components/Icons';

const SideBar = ({ active }) => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState();
    const [menuState, setMenuState] = useState({});
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        const userType = localStorage.getItem("ucat");
        const menu = JSON.parse(localStorage.getItem("active_menu"));
        menu && setMenuState(menu);
        setUserType(userType);
    }, []);

    const handleMenState = (menu) => {
        setMenuState({ [menu]: !menuState[menu] });
        localStorage.setItem("active_menu", JSON.stringify({ [menu]: true }));
    }

    return (
        <ProSidebar collapsed={collapsed} style={{height: "100%", backgroundColor: "red"}}>
            <SidebarHeader className={styles.logoContainer}>
                <img src={logo} className={styles.logo} alt="logo" onClick={() => setCollapsed(!collapsed)} />
            </SidebarHeader>
            <SidebarContent className={styles.container}>
                <Menu iconShape="round">
                    {userType === "admin" && <>
                        <SubMenu title="Pursuit Dashboard" icon={<DashboardIcon color="#e8dbc3" />} open={menuState.menu_1 === true} onOpenChange={() => handleMenState("menu_1")}>
                            <MenuItem className={active === routes.DashboardPage ? styles.active : styles.inactive} icon={<DashboardIcon color="#e8dbc3" />}>Dashboard<Link onClick={() => {}} to={routes.DashboardPage} /></MenuItem>
                            <MenuItem className={active === routes.TalentStylePage ? styles.active : styles.inactive} icon={<TalentSkillIcon color="#e8dbc3" />}>Competencies &amp; Styles <Link to={routes.TalentStylePage} /></MenuItem>
                            <MenuItem className={active === routes.ReportPage ? styles.active : styles.inactive} icon={<ReportIcon color="#e8dbc3" />}>Report <Link to={routes.ReportPage} /></MenuItem>
                        </SubMenu>
                        <SubMenu title="General" icon={<DashboardIcon color="#e8dbc3" />}  open={menuState.menu_2 === true} onOpenChange={() => handleMenState("menu_2")}>
                            <MenuItem className={active === routes.UserRequestPage ? styles.active : styles.inactive} icon={<DashboardIcon color="#e8dbc3" />}>User Request<Link onClick={() => {}} to={routes.UserRequestPage} /></MenuItem>
                            <MenuItem className={active === routes.CompanyPage ? styles.active : styles.inactive} icon={<RequestIcon color="#e8dbc3" />}>Companies<Link to={routes.CompanyPage} /></MenuItem>
                            <MenuItem className={active === routes.CompanyUserPage ? styles.active : styles.inactive} icon={<RequestIcon color="#e8dbc3" />}>Company Users<Link to={routes.CompanyUserPage} /></MenuItem>
                            <MenuItem className={active === routes.AssessorsPage ? styles.active : styles.inactive} icon={<AssesorIcon color="#e8dbc3" />}>Assessors<Link to={routes.AssessorsPage} /></MenuItem>
                            <MenuItem className={active === routes.GamesPage ? styles.active : styles.inactive} icon={<GamesIcon color="#e8dbc3" />}>Games<Link to={routes.GamesPage} /></MenuItem>
                            <MenuItem className={active === routes.ApplicationPage ? styles.active : styles.inactive} icon={<ReportIcon color="#e8dbc3" />}>Applications<Link to={routes.ApplicationPage} /></MenuItem>
                        </SubMenu>
                    </>}{
                        userType === "assessor" && <>
                            <MenuItem className={active === routes.AssessorDashboard ? styles.active : styles.inactive} icon={<DashboardIcon color="#e8dbc3" />}>Dashboard<Link to={routes.AssessorDashboard} /></MenuItem>
                            {/* <MenuItem className={active === routes.AssessmentBatches ? styles.active : styles.inactive} icon={<DashboardIcon color="#e8dbc3" />}>Batches<Link to={routes.AssessmentBatches} /></MenuItem>
                            <MenuItem className={active === routes.AssessmentCandidates ? styles.active : styles.inactive} icon={<DashboardIcon color="#e8dbc3" />}>Candidates<Link to={routes.AssessmentCandidates} /></MenuItem> */}
                            <MenuItem className={active === routes.AssessorProfile ? styles.active : styles.inactive} icon={<ProfileIcon color="#e8dbc3" />}>User Profile<Link to={routes.AssessorProfile} /></MenuItem>
                            <MenuItem className={active === routes.AssessorReport ? styles.active : styles.inactive} icon={<ReportIcon color="#e8dbc3" />}>Reports<Link to={routes.AssessorReport} /></MenuItem>
                        </>
                    }{
                        userType === "company-admin" && <>
                            <SubMenu title="Pursuit Dashboard" icon={<DashboardIcon color="#e8dbc3" />} open={menuState.menu_1 === true} onOpenChange={() => handleMenState("menu_1")}>
                                <MenuItem className={active === routes.AssessmentPage ? styles.active : styles.inactive} icon={<AssessmentIcon color="#e8dbc3" />}>Request<Link to={routes.AssessmentPage} /></MenuItem>
                                <MenuItem className={active === routes.CompanyApplications ? styles.active : styles.inactive} icon={<ReportIcon color="#e8dbc3" />}>Applications<Link to={routes.CompanyApplications} /></MenuItem>
                                <MenuItem className={active === routes.CompanyReport ? styles.active : styles.inactive} icon={<ReportIcon color="#e8dbc3" />}>Reports<Link to={routes.CompanyReport} /></MenuItem>
                            </SubMenu>
                            <SubMenu title="General" icon={<DashboardIcon color="#e8dbc3" />} open={menuState.menu_2 === true} onOpenChange={() => handleMenState("menu_2")}>
                                <MenuItem className={active === routes.UserProfile ? styles.active : styles.inactive} icon={<ProfileIcon color="#e8dbc3" />}>User Profile<Link to={routes.UserProfile} /></MenuItem>
                                <MenuItem className={active === routes.CreateUser ? styles.active : styles.inactive} icon={<RequestIcon color="#e8dbc3" />}>Create User<Link to={routes.CreateUser} /></MenuItem>
                            </SubMenu>
                        </>
                    }
                </Menu>
            </SidebarContent>
            <SidebarFooter className={styles.footerContainer}>
                <Menu iconShape="round">
                    <SubMenu onOpenChange={() => { 
                        navigate(routes.LandingPage, { replace: true });
                        store.removeItem("user") }} title="Sign Out" icon={<SignOutIcon />}>
                    </SubMenu>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
    )
}


export default SideBar;