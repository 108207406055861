import React, { useEffect, useState } from "react";
import { useFilePicker } from 'use-file-picker';
import { ToastContainer } from 'react-toastify';

import store from "../../api/store";
import Modal from "../../components/Modal";
import Wrapper from "../../layouts/Wrapper";
import { FaUser, FaPlusCircle } from 'react-icons/fa';
import styles from "../../styles/UserProfile.module.css";
import { Form, SubmitButton, TextField } from "../../components/form";
import schemas from "../../utils/schemas";
import { helpers } from "../../utils/helpers";
import useApi from "../../hooks/useApi";
import cadminApis from "../../api/cadmin";
import routes from "../../routes";


const UserProfile = () => {

    const [openFileSelector, { filesContent }] = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: false,
    });

    const [companyData, setCompanyData] = useState();

    const user = JSON.parse(localStorage.getItem("user"));

    const company = JSON.parse(localStorage.getItem("companyInfo"));


    const [modalShow, setModalShow] = React.useState(false);

    const [activePage, setActivePage] = useState("first");

    const [fileToUpload, setFileToUpload] = useState();

    const updateUserApi = useApi(cadminApis.updateUser);
    const [editAssessor, setEditAssessor] = useState();

    const [imgDisplay, setImgDisplay] = useState({});

    const uploadCompanyImageApi = useApi(cadminApis.uploadCompanyImage);


    const [games, setGames] = useState([]);

    const getGamesApi = useApi(cadminApis.getGames);

    useEffect(() => {
        getGames();
    }, []);

    const getGames = async () => {
        const res = await getGamesApi.request();
        if (res.ok) setGames(res.data.data);
    }


    const handleEdit = async (data) => {

        const res = await updateUserApi.request({ ...data })
        if (res.ok) {
            const userData = { code: user.code, companyCode: user.companyCode, companyEmail: user.companyEmail, companyId: user.companyId, companyName: user.companyName, companyPhoneNumber: user.companyPhoneNumber, logo: user.logo, role: user.role, jwtToken: user.jwtToken, email: res.data.data.email, id: res.data.data.id, firstName: res.data.data.firstName, lastName: res.data.data.lastName, surname: res.data.data.surname, password: res.data.data.password }
            store.setItem("user", JSON.stringify(userData));
            helpers.toaster.success("User Profile Updated Successfully");
            window.location.reload(false);
        }
        updateUserApi.setLoading(false);

        //return navigate(routes.);
    }
    
    const onFileChange = (e) => {

        const upload = e.target.files[0]
        setImgDisplay(URL.createObjectURL(upload));
        setFileToUpload(upload);
    }

    const handleUpload = async () => {

        const res = await uploadCompanyImageApi.request(fileToUpload);
        if (res.ok) {
            store.setItem("companyInfo", JSON.stringify(res.data.data));
            helpers.toaster.success("Company Logo Updated Successfully");
            window.location.reload(false);
        }
    }

    return (
        <Wrapper activePage={routes.UserProfile}>
            <h2 align="center">User Profile</h2>
            <div className="row m-0 p-5">
                <div className="col-md-4">
                    <div className={styles.card}>
                        {/* <div>{fileToUpload.length === undefined ? <label for="inputFile" style={{ cursor: "pointer" }}><FaUser className={styles.company} /><input type="file" name="companyLogo" id="inputFile" onChange={onFileChange} style={{ display: "none" }}></input></label> : <img src={fileToUpload} width={120} className={styles.company} alt="" />}<br></br></div>
                                <button type="submit" onClick={() => handleUpload()} className="btn btn-dark">Update Logo</button> */}

                        {
                            company.logo === null ? <> {imgDisplay.length === undefined ? <label for="inputFile" style={{ cursor: "pointer" }}><FaUser className={styles.company} /><input type="file" name="companyLogo" id="inputFile" onChange={onFileChange} style={{ display: "none" }}></input></label> : <div><img src={imgDisplay} width={120} className={styles.company} alt="" /><br></br><button type="submit" onClick={() => handleUpload()} className="btn btn-dark">Update Logo</button></div>} </> 
                            :
                                <> {imgDisplay.length === undefined ? <label for="inputFile" style={{ cursor: "pointer" }}><img src={company.logo} width={120} className={styles.company} alt="" /><input type="file" name="companyLogo" id="inputFile" onChange={onFileChange} style={{ display: "none" }}></input></label> : <div><img src={imgDisplay} width={120} className={styles.company} alt="" /><br></br>
                                    <button type="submit" onClick={() => handleUpload()} className="btn btn-dark">Update Logo</button></div>} </> 
                        }

                        {/* {
                            filesContent.length > 0 ? <><img src={filesContent[0].content} width={120} className={styles.company} alt="" /><br></br><input type="file" name="companyLogo" style={{}} onChange={onFileChange}></input>
                                <button type="submit" onClick={() => handleUpload()} className="btn btn-dark">Update Logo</button></> :
                                <div className={styles.upload} onClick={() => openFileSelector()}>
                                    <FaUser className={styles.company} />
                                </div>
                        } */}
                        {/* <FaUser className={styles.company} /> */}
                        <h5 className="mt-3"><b>{company.name}</b></h5>
                        <h6>{company.email}</h6>
                    </div>
                </div>
                <div className="col-md-8">
                    {activePage === "first" && <div className={styles.card1}>
                        <ul>
                            <li><button className={styles.active}>Account Settings</button></li>
                            {/* <li><button onClick={() => setActivePage("second")}>Billing Information</button></li> */}
                        </ul>
                        <hr></hr>
                        <Form
                            enableReinitialize
                            validationSchema={schemas.editCompanyUserSchema}
                            initialValues={{
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email,
                                surname: user.surname,
                            }}
                            onSubmit={handleEdit} className={styles.form1}
                        >
                            <div className="row mt-5 mx-4 my-4">
                                <div className="col-lg-6">
                                    <TextField name={"firstName"} label={"FirstName"} className={styles.input} />
                                </div>
                                <div className="col-lg-6">
                                    <TextField name={"lastName"} label={"Last Name"} className={styles.input} />
                                </div>
                                <div className="col-lg-12">
                                    <TextField name={"email"} label={"Email Address"} className={styles.input} />
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <SubmitButton title={updateUserApi.loading ? "Updating..." : "Update"} className={styles.sendCode} />
                            </div>
                        </Form>
                    </div>}

                    {activePage === "second" && <div className={styles.card1}>
                        <ul>
                            <li><button onClick={() => setActivePage("first")}>Account Settings</button></li>
                            <li><button className={styles.active}>Billing Information</button></li>
                        </ul>
                        <hr></hr>
                    </div>}
                </div>
            </div>
        </Wrapper>
    )
};

export default UserProfile;