import React from 'react';
import styles from "../styles/Report.module.css";
import mask from "../images/mask.svg";

const CompetencyReport = ({ reports, applicationDetails, candidateName }) => {
    const items = reports.filter((candidate) => candidate.candidateName === candidateName);
    if (!items.length) return <> </>;
    const item = items[0];

    console.log(item);
    
  return (
      <div style={{margin: "0 5% 0 5%"}}>
            <div className={styles.report}>
                <div className="row m-0">
                    <div className="col-lg-3 p-0">
                        <img src={mask} className={styles.people} alt=""></img>
                    </div>
                    <div className="col-lg-7 mt-4">
                        <div className={styles.info}><p>Candidate name:<span> {item.candidateName}</span></p>
                            <p>Company Name:<span> {applicationDetails.companyName}</span> </p>
                            <p>Assessment Date:<span> {applicationDetails.dateOfEvent}</span> </p>
                            <p>Assessment Time:<span> {applicationDetails.timeOfEvent}</span> </p>
                        </div>
                    </div>
                </div>
            </div>
          {item.report.map((compe, key) => 
            <div className={styles.card} key={key}>
              <h3 align="center">{compe.competencyName}</h3>
                <div className="row p-5">
                    <div className="col-lg-5">
                        <div className="row"><div className="col-sm-6"><h4>0</h4></div><div className="col-sm-6 text-end"><h4>100%</h4></div></div>
                        <div style={{ zIndex: "-9999", height: "15px", margin: "20px 0px", backgroundImage: "linear-gradient(to right, red, orange, green)", borderRadius: "10px" }}></div>
                        <div style={{ height: "15px", width: "15px", border: "1px solid grey", borderRadius: "15px", backgroundColor: "#fff", zIndex: "9999", marginTop: "-35px", marginLeft: `${compe.average - 2}%` }}></div>
                        <div className="row mt-4"><div className="col-sm-4"><h4>Insufficient</h4></div><div className="col-sm-4 text-center"><h4>Basic</h4></div><div className="col-sm-4 text-end"><h4>Good</h4></div></div>
                    </div>
                    <div className="col-lg-1"></div>

                  <div className="col-lg-6">{compe.average > 80 ? <p>{compe.goodText}</p> : compe.average > 20 && compe.average < 81 ? <p>{compe.basicText}</p> : <p>{compe.insufficientText}</p> }
                    </div>
                </div>
            </div>
            )}
      </div>
  )
}

export default CompetencyReport;