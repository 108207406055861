import React from "react";
import { Button, Modal } from "react-bootstrap";


function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Modal heading
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Centered Modal</h4>
                <p>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                    dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                    consectetur ac, vestibulum at eros.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

function BootstrapModal() {
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Launch vertically centered modal
            </Button>

            <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    );
}

export default BootstrapModal;

// class BootstrapModal extends React.Component {

//     constructor() {
//         super();
//         this.state = {
//             showHide: false
//         }
//     }

//     handleModalShowHide() {
//         this.setState({ showHide: !this.state.showHide })
//     }

//     render() {
//         return (
//             <div>
//                 <Button variant="primary" onClick={() => this.handleModalShowHide()}>
//                     Launch demo modal
//                 </Button>

//                 <Modal
//                     size="lg"
//                     aria-labelledby="contained-modal-title-vcenter"
//                     centered show={this.state.showHide}>
//                     <Modal.Header closeButton onClick={() => this.handleModalShowHide()}>
//                         <Modal.Title>Modal heading</Modal.Title>
//                     </Modal.Header>
//                     <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
//                     <Modal.Footer>
//                         <Button variant="secondary" onClick={() => this.handleModalShowHide()}>
//                             Close
//                         </Button>
//                         <Button variant="primary" onClick={() => this.handleModalShowHide()}>
//                             Save Changes
//                         </Button>
//                     </Modal.Footer>
//                 </Modal>

//             </div>
//         )
//     }

// }

// export default BootstrapModal;