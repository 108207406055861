import React, { useState } from "react";
import { useFormikContext } from "formik";
import { MultiSelect } from "react-multi-select-component";

import styles from "../../styles/form/selectfield.module.css";
import { helpers } from "../../utils/helpers";

const SelectField = ({ items, name, label, labelField, valueField, multiple, style, ...otherProps }) => {
  const { setFieldTouched, setFieldValue, errors, touched, values } = useFormikContext();
  const options = items.map((item) => helpers.renameKeys(item, {[labelField]: "label", [valueField]: 'value'}));

  return (
    <div className={styles.container}>
      <p className={styles.label}>{label}</p>
      <div style={style} className={styles.fieldContainer}>
        {
          multiple ?
            <MultiSelect
              options={options}
              value={values[name] || []}
              onChange={(items) => setFieldValue(name, items)}
              labelledBy={`Select ${name.toUpperCase()}`}
            />
          :
          <select
            {...otherProps}
            className={styles.field}
            defaultValue={""}
            onBlur={() => setFieldTouched(name)}
            onChange={(e) => setFieldValue(name, e.target.value)}
          >
              <option value="" disabled>Select {label}</option>
              {items.map((item, key) => <option key={key} value={item[valueField]}>{item[labelField]}</option>)}
          </select>
        }
        {touched[name] && (<div className={styles.error}>{errors[name]}</div>)}
      </div>
    </div>
  );
};

export default SelectField;