import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import SideBar from './SideBar';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import styles from '../styles/wrapper.module.css';
import routes from '../routes';

const Wrapper = ({ activePage, children }) => {
     const [userType, setUserType] = useState();
    useEffect(() => {
        const userType = localStorage.getItem("ucat");
        setUserType(userType);
    }, []);

    const user = JSON.parse(localStorage.getItem("user"));

    return (
        <div style={{ display: 'flex' }}>
            <SideBar active={activePage} />
            <div>
                <nav className={styles.topMenu}>
                    <div className={styles.topMenuContainer}>
                        <div className="col-lg-3 mt-4 mb-2 text-start">
                            <span style={{ display: "inline-block", fontSize: "23px", color: "#000", marginRight: "10px", marginLeft: "20px" }}>{user.role === "User" ? "Company User" : user.role}</span>
                            {(userType === 'company-admin' || userType === 'assessor') && <Link to={userType === "company-admin" ? routes.UserProfile : routes.AssessorProfile} style={{display: "inline-block"}}>
                                <span className={styles.user}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.787" height="19.787" viewBox="0 0 19.787 19.787"><path id="ic_person_24px" d="M13.894,13.894A4.947,4.947,0,1,0,8.947,8.947,4.945,4.945,0,0,0,13.894,13.894Zm0,2.473c-3.3,0-9.894,1.657-9.894,4.947v2.473H23.787V21.314C23.787,18.024,17.2,16.367,13.894,16.367Z" transform="translate(-4 -4)" fill="#3E3B4E" /></svg>
                                </span>
                            </Link>}
                        </div>
                        <div className="col-lg-8 mt-4 mb-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 33.434 32.696"><path id="ic_notifications_active_24px" d="M11.336,5.149l-2.4-2.4A17.48,17.48,0,0,0,2.03,15.914H5.383A14.161,14.161,0,0,1,11.336,5.149ZM32.111,15.914h3.353A17.587,17.587,0,0,0,28.556,2.752l-2.381,2.4A14.244,14.244,0,0,1,32.111,15.914Zm-3.3.838c0-5.148-2.75-9.457-7.545-10.6V5.015a2.515,2.515,0,1,0-5.03,0v1.14c-4.812,1.14-7.545,5.433-7.545,10.6v8.384L5.333,28.489v1.677H32.161V28.489l-3.353-3.353ZM18.747,35.2a2.937,2.937,0,0,0,.671-.067,3.4,3.4,0,0,0,2.414-1.979,3.366,3.366,0,0,0,.252-1.308H15.377a3.378,3.378,0,0,0,3.37,3.353Z" transform="translate(-2.03 -2.5)" fill="#3e3b4e" /></svg>
                        </div>
                        <div className={styles.langContainer}>
                            <span>EN</span>
                            <span>NL</span>
                            <span>FR</span>
                        </div>
                    </div><hr></hr>
                </nav>
                <div style={{ maxHeight: "50vh", width: "80vw", maxWidth: "95vw" }}>
                    { children }
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Wrapper