import React, { useState } from "react";
import store from "../../api/store";
import Modal from "../../components/Modal";
import Wrapper from "../../layouts/Wrapper";
import { FaUser, FaPlusCircle } from 'react-icons/fa';
import styles from "../../styles/AssessorUserProfile.module.css";
import { PuffLoader } from "react-spinners";
import { helpers } from "../../utils/helpers";
import useApi from "../../hooks/useApi";
import assessorApis from "../../api/assessor";
import routes from "../../routes";
import { Form, SubmitButton, TextField } from "../../components/form";
import schemas from "../../utils/schemas";
import { Link } from "react-router-dom";


const AssessorProfile = () => {


    const user = JSON.parse(localStorage.getItem("user"));
    const assessor = JSON.parse(localStorage.getItem("companyInfo"));

    const profilePicture = assessor.imagePath === undefined ? assessor.logo : assessor.imagePath;

    console.log(profilePicture);

    const editAssessorApi = useApi(assessorApis.editAssessor);
    const [editAssessor, setEditAssessor] = useState();


    const handleEdit = async (data) => {
        
        const res = await editAssessorApi.request({ ...data })
        if (res.ok) {
            const userData = { code: res.data.data.code, companyName: res.data.data.companyName, logo: user.logo, role: user.role, jwtToken: user.jwtToken, email: res.data.data.email, id: res.data.data.id, firstName: res.data.data.firstName, lastName: res.data.data.lastName, phoneNumber: res.data.data.phoneNumber, password: res.data.data.password };
            store.setItem("user", JSON.stringify(userData));
            helpers.toaster.success("User Profile Updated Successfully");
            window.location.reload(false);
        }
        editAssessorApi.setLoading(false);

    }


    const [fileToUpload, setFileToUpload] = useState();
    const [imgDisplay, setImgDisplay] = useState({});
    const uploadImageApi = useApi(assessorApis.uploadImage);
    const onFileChange = (e) => {

        const upload = e.target.files[0]
        setImgDisplay(URL.createObjectURL(upload));
        setFileToUpload(upload);
    }

    const handleUpload = async () => {

        const res = await uploadImageApi.request(fileToUpload);
        if (res.ok) {
            store.setItem("companyInfo", JSON.stringify(res.data.data));
            helpers.toaster.success("Assessor Image Updated Successfully");
            window.location.reload(false);
        }
    }

    return (
        <Wrapper activePage={routes.UserProfile}>
            <h2 align="center">User Profile</h2>
            <div className="row m-0 p-0">
                <div className="col-md-1"></div>
                <div className="col-md-3">
                    <div className={styles.card}>
                        {/* <div>{fileToUpload === undefined ? <label for="inputFile" style={{ cursor: "pointer" }}><FaUser className={styles.company} /><input type="file" name="companyLogo" id="inputFile" onChange={onFileChange} style={{ display: "none" }}></input></label> : <img src={fileToUpload} width={120} className={styles.company} alt="" />}<br></br></div>
                                <button type="submit" onClick={() => handleUpload()} className="btn btn-dark">Update Logo</button> */}

                        {/* {
                            imgDisplay.length === undefined ? <label for="inputFile" style={{ cursor: "pointer" }}><FaUser className={styles.company} /><input type="file" name="companyLogo" id="inputFile" onChange={onFileChange} style={{ display: "none" }}></input></label> : <div><img src={imgDisplay} width={120} className={styles.company} alt="" /><br></br><button type="submit" onClick={() => handleUpload()} className="btn btn-dark">Update Logo</button></div>
                        } */}

                        {
                            profilePicture === null ? <> {imgDisplay.length === undefined ? <label for="inputFile" style={{ cursor: "pointer" }}><FaUser className={styles.company} /><input type="file" name="companyLogo" id="inputFile" onChange={onFileChange} style={{ display: "none" }}></input></label> : <div><img src={imgDisplay} width={120} className={styles.company} alt="" /><br></br><button type="submit" onClick={() => handleUpload()} className="btn btn-dark">Update Logo</button></div>} </>
                                :
                                <> {imgDisplay.length === undefined ? <label for="inputFile" style={{ cursor: "pointer" }}><img src={`https://${profilePicture}`} width={120} className={styles.company} alt="" /><input type="file" name="companyLogo" id="inputFile" onChange={onFileChange} style={{ display: "none" }}></input></label> : <div><img src={imgDisplay} width={120} className={styles.company} alt="" /><br></br>
                                    <button type="submit" onClick={() => handleUpload()} className="btn btn-dark">Update Logo</button></div>} </>
                        }
                    </div>
                </div>
                <div className="col-md-8 m-auto">
                    <div className={styles.card1}>
                        <ul>
                            <li><button className={styles.active}>Account Settings</button></li>
                        </ul>
                        <hr></hr>
                        <Form
                            enableReinitialize
                            validationSchema={schemas.editAssessorSchema}
                            initialValues={{
                                firstName: user.firstName,
                                lastName: user.lastName,
                                email: user.email,
                                companyName: user.companyName,
                                phoneNumber: user.phoneNumber,
                            }}
                            onSubmit={handleEdit} className="px-5"
                        >
                            <div className="row mt-5">
                                <div className="col-lg-12">
                                    <TextField name={"companyName"} label={"Company Name"} className={styles.input} />
                                </div>
                                <div className="col-lg-6">
                                    <TextField name={"firstName"} label={"FirstName"} className={styles.input} />
                                </div>
                                <div className="col-lg-6">
                                    <TextField name={"lastName"} label={"LastName"} className={styles.input}  />
                                </div>
                                <div className="col-lg-6">
                                    <TextField name={"email"} label={"Email Address"} className={styles.input}  />
                                </div>
                                <div className="col-lg-6">
                                    <TextField name={"phoneNumber"} label={"Phone Number"} className={styles.input}  />
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <SubmitButton title={editAssessorApi.loading ? "Updating..." : "Update"} className={styles.sendCode} />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};

export default AssessorProfile;