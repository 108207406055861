import React, { useEffect, useState } from "react";

import { FaArrowRight } from 'react-icons/fa';
import { faX } from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from "react-multi-select-component";
import useApi from "../../hooks/useApi";
import store from "../../api/store";
import cadminApis from "../../api/cadmin";
import { Modal } from "react-bootstrap";
import { PuffLoader } from "react-spinners";
import Wrapper from "../../layouts/Wrapper";
import { helpers } from "../../utils/helpers";
import { FaEdit, FaTrash } from 'react-icons/fa';
import styles from "../../styles/CompanyApplication.module.css";
import routes from "../../routes";
import validationSchemas from "../../utils/schemas";
import GameField from "../../components/form/GameField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, SubmitButton, TextField } from "../../components/form";
import FinishAssessmentButton from "../../components/form/FinishAssessmentButton";

const CompanyApplications = () => {

    const [companyApplicationToDelete, setCompanyApplicationToDelete] = useState();
    const deleteCompanyApplicationApi = useApi(cadminApis.deleteCompanyApplication);


    const [updateApplication, setUpdateApplication] = useState();
    const updateCompanyApplicationApi = useApi(cadminApis.updateCompanyApplication);

    const getCompanyApplicationsApi = useApi(cadminApis.getApplications);
    const [companyApplications, setCompanyApplications] = useState([]);

    useEffect(() => {
        getApplications();
    }, []);

    const getApplications = async () => {
        const res = await getCompanyApplicationsApi.request();
        if (res.ok) setCompanyApplications(res.data.data);
    }


    const handleApplicationDelete = async (code) => {
        const del = window.confirm("Are you sure you wants to delete this Company Application?");
        if (del) {
            const res = await deleteCompanyApplicationApi.request(code);
            if (res.ok) {
                helpers.toaster.success("Company Application Successfully Deleted");
                window.location.reload(false);
            }
            if (!res.ok) {
                helpers.toaster.error(res.data.responseMessage);
            }
        }
    }


    ///////////////////////////////////////////////////////////////////////////////
    const skillApi = useApi(cadminApis.getSkills);
    const talentApi = useApi(cadminApis.getTalents);
    const gamesApi = useApi(cadminApis.getGames);
    const filteredGamesApi = useApi(cadminApis.getFilteredGames);
    const createApplicationApi = useApi(cadminApis.createApplication);
    const [modalVisible, setModalVisible] = React.useState(false);
    const [error, setError] = useState({});
    const [skillOptions, setSkillOptions] = useState([]);
    const [compentencyOptions, setCompetencyOptions] = useState([]);

    const [selectedSkillOptions, setSelectedSkillOptions] = useState([]);
    const [selectedCompentencyOptions, setSeletedCompetencyOptions] = useState([]);
    useEffect(() => {
        getData();
    }, []);
    useEffect(() => {
        if (!isFiltered()) return filteredGamesApi.resetData();

        const filters = {
            skill: selectedSkillOptions.map(item => item.value),
            competency: selectedCompentencyOptions.map(item => item.value)
        };

        handleFilters(filters);
    }, [selectedSkillOptions, selectedCompentencyOptions]);


    const getData = async () => {
        const res = await Promise.all([
            skillApi.request(),
            talentApi.request(),
            gamesApi.request()
        ]);
        const skills = res[0].data.data.map((item) => helpers.renameKeys(item, { 'name': "label", 'id': 'value' }));
        const competencies = res[1].data.data.map((item) => helpers.renameKeys(item, { 'name': "label", 'id': 'value' }));
        setSkillOptions(skills);
        setCompetencyOptions(competencies);
    }

    const handleApplicationUpdate = async (data) => {
        const res = await updateCompanyApplicationApi.request({...data, code: updateApplication?.code});

        if (res.status === 200) {
            setModalVisible(false);
            helpers.toaster.success(res.data.responseMessage);
            //window.location.reload(false);
        }
        else {
            setModalVisible(false);
            helpers.toaster.error(res.data.responseMessage);
            //window.location.reload(false);
        }
        window.location.reload(false);
    }

    const handlePreSubmit = (data) => {
        setError("");
        if (data?.games?.length > 0) return setModalVisible(true);

        setError({ message: "No game selected. please select a game." });
    }

    const isFiltered = () => selectedSkillOptions.length + selectedCompentencyOptions.length > 0;

    const handleFilters = async (filters) => {
        try {
            const res = await filteredGamesApi.request(filters);
            console.log(res);
            console.log(filters);
        } catch (error) {
            console.log(error);
        }
    }
    ///////////////////////////////////////////////////////////////////////////////

    return (
        <Wrapper activePage={routes.CompanyApplications}>
            <h2 align="center" className="mt-5">Applications</h2>
            <div className="row m-0 p-0">
                <div className="col-lg-10 m-auto">
                    <div className={styles.card1}>
                        <table>
                            <tr style={{ borderBottom: "1px solid grey" }}>
                                <td className={styles.left}><span>Games</span></td>
                                <td className={styles.left}><span>Assessment Status</span></td>
                                <td className={styles.left}><span>Date / Time</span></td>
                                <td className={styles.left}><span>Action</span></td>
                            </tr>
                            {companyApplications.items?.length === 0 && !getCompanyApplicationsApi.loading && <tr>No Company Users available </tr>}
                            {getCompanyApplicationsApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                companyApplications.items?.map((items, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left}>{items.applicationGames.map((games) => <font>{games.name} <br></br></font>)}</td>
                                    <td className={styles.left}>{items.isPlayed === true ? <>Assessed</> : <>Not Assessed</>}</td>
                                    <td className={styles.left}>{items.dateOfEvent} / {items.timeOfEvent}</td>
                                    <td className={styles.left} style={{ padding: "10px 0px" }}>
                                        <button type="submit" hidden={items.isPlayed} onClick={() => setUpdateApplication(items)} className={styles.success}> <FaEdit /> {updateCompanyApplicationApi.loading ? "Updating.." : "Update"} </button>&nbsp;&nbsp;
                                        {/* <button type="submit" onClick={() => handleApplicationDelete(items.code)} className={styles.danger}> <FaTrash /> {companyApplicationToDelete === items.code && deleteCompanyApplicationApi.loading ? "Deleting.." : "Delete"} </button> */}
                                    </td>
                                </tr>)
                            }
                        </table>
                        <Modal
                            centered
                            size="xl"
                            show={updateApplication?.code}
                            onHide={() => setUpdateApplication(null)}
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Update Application
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="p-4">
                                <Form
                                    validationSchema={validationSchemas.assessmentSchema}
                                    initialValues={{
                                        games: [],
                                        dateOfEvent: "",
                                        timeOfEvent: "",
                                    }}
                                    onSubmit={handleApplicationUpdate}
                                >

                                    <div className={styles.form}>
                                        <div>
                                            <h4>Current Application Games</h4>
                                            {/* {updateApplication?.applicationGames?.map(games => <div className="row card"><div className="col-md-3 m-2">Name: {games.name}</div><div className="col-md-7 m-2">Name: {games.description}</div><div className="col-md-4 m-2">Skills: {games.skill.map(skills => <label>{skills.name}{games.skill.length > 1 ? <>, &nbsp; </> : "."}</label>)}</div><div className="col-md-4 m-2">Competencies: {games.competency.map(competencies => <label>{competencies.name}{games.competency.length > 1 ? <>, &nbsp; </> : "."}</label>)}</div></div>)} */}
                                            <table className="table table-bordered"><thead> <td>Game Name</td> <td>Game Description</td> <td>Styles</td> <td>Competencies</td></thead>
                                                {updateApplication?.applicationGames?.map(games => <tr> <td>{games.name}</td><td>{games.description}</td><td>{games.skill === null ? "No Styles Available" : games.skill.map(skills => <>{skills.name}{games.skill.length > 1 ? <>, &nbsp; </> : "."}</>)}</td><td>{games.competency === null ? "No Competencies Available" : games.competency.map(competencies => <label>{competencies.name}{games.competency.length > 1 ? <>,&nbsp;</> : "."}</label>)}</td></tr>)}
                                            </table>
                                        </div>
                                        <div>
                                            <h4>Filter by:</h4>
                                            <div className={styles.filters}>
                                                <div>
                                                    <label className={styles.label}>Styles</label>
                                                    <MultiSelect
                                                        isLoading={skillApi.loading}
                                                        shouldToggleOnHover
                                                        options={skillOptions}
                                                        className={styles.filter}
                                                        value={selectedSkillOptions}
                                                        labelledBy={"Filter by Styles"}
                                                        onChange={(items) => setSelectedSkillOptions(items)}
                                                    />
                                                </div>
                                                <div>
                                                    <label className={styles.label}>Competencies</label>
                                                    <MultiSelect
                                                        isLoading={talentApi.loading}
                                                        shouldToggleOnHover
                                                        className={styles.filter}
                                                        options={compentencyOptions}
                                                        labelledBy={"Filter by Competencies"}
                                                        value={selectedCompentencyOptions}
                                                        onChange={(items) => setSeletedCompetencyOptions(items)}
                                                    />
                                                </div>
                                                {isFiltered() && (
                                                    <div className={styles.clear} onClick={() => {
                                                        setSelectedSkillOptions([]);
                                                        setSeletedCompetencyOptions([]);
                                                    }}>
                                                        <label className={styles.label}>Clear All</label>
                                                        <FontAwesomeIcon title="Clear" icon={faX} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <h2 className={styles.title}>{gamesApi.loading ? "Loading" : "Showing"} {isFiltered() ? "Filtered" : "All"} Games</h2>
                                        <div className="mb-5 pb-1 text-end">
                                            {error.message && <p className={styles.errorMessage}>{error.message}</p>}
                                            <FinishAssessmentButton onClick={handlePreSubmit} styles={styles.next} title="FINISH" icon={<FaArrowRight className={styles.faArrowRight} />} />
                                        </div>
                                        <p className={styles.subtitle}>{isFiltered() ? "Below are the games recommended for your selected filters" : "Select games to proceed"}</p>
                                        {gamesApi.loading || filteredGamesApi.loading ?
                                            <div className={styles.loader}>
                                                <p>Loading...</p>
                                                <PuffLoader loading={true} />
                                            </div>
                                            :
                                            <>
                                                {(isFiltered() ? filteredGamesApi.data : gamesApi.data).map((game) => <GameField key={game.id} game={game} name={"games"} />)}
                                                <div>
                                                    {isFiltered() && filteredGamesApi.data.length === 0 && <div className={styles.noGames}>No games found with the selected filters</div>}
                                                    {gamesApi.data.length === 0 && <div className={styles.noGames}>No games available</div>}
                                                </div>
                                                <div className="mt-5 mb-5">
                                                    {error.message && <p className={styles.errorMessage}>{error.message}</p>}
                                                    <FinishAssessmentButton onClick={handlePreSubmit} styles={styles.next} title="FINISH" icon={<FaArrowRight className={styles.faArrowRight} />} />
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <Modal show={modalVisible} onHide={() => setModalVisible(false)} title="Recruit Pursuit Request Form">
                                        <form method="post" onSubmit="return false" className={styles.form1}>
                                            <span>Select date and time of event to complete your application</span>
                                            <div className="row mt-5">
                                                <div className="col-lg-6">
                                                    <label>Date of event</label>
                                                    <TextField type="date" className="form-control" name="dateOfEvent" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Time of event</label>
                                                    <TextField type="time" className="form-control" name="timeOfEvent" />
                                                </div>
                                            </div>
                                            <div className="text-center mt-5">
                                                <SubmitButton className={styles.sendCode} title={updateCompanyApplicationApi.loading ? "Updating..." : "Update"} />
                                            </div>
                                        </form>
                                    </Modal>
                                </Form>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};

export default CompanyApplications;