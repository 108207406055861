import React, { useEffect, useState } from "react";
import routes from "../routes";
import { Link } from 'react-router-dom';
import styles from "../styles/Landing.module.css";
import logo from "../images/LogoParody.svg";
import homeBg from "../images/img1.svg";
import recruit from "../images/recruit.svg";

const LandingPage = () => {

    /////////////////////////////////////////////////////////////////////
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    ////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row m-0 p-0">
                <div className="col-lg-4 p-0">
                    <div style={{ height: `${screenSize.dynamicHeight}px`, textAlign: "center" }}>
                        <div className={styles.content}>
                            <img src={logo} className={styles.logo} alt=""></img>
                            <h1 className="mb-5">Welcome</h1>
                            <h5 className="mb-3">Select your user type to get started</h5>  
                            <Link to={routes.AssessorLogin} className="btn btn-outline-dark py-4 px-4 pt-3 pb-3" style={{ marginRight: "10px" }}>Assessor</Link>
                            <Link to={routes.RequestCode} className="btn btn-outline-dark py-4 px-4 pt-3 pb-3">Company Admin</Link>
                        </div>
                        <footer className={styles.footer}>
                            <p>© 2022 PARODy platform is powered by Pilipili Games</p>
                        </footer>
                    </div>
                </div>
                <div className="col-lg-8 p-0">
                    <div className={styles.right}>
                        <div>
                            <img src={recruit} alt="" className={styles.logo}></img>
                        </div>
                        <h2 className={styles.subtitle}>Serious Recruitment Games</h2>
                        <div>
                            <img src={homeBg} alt="" className={styles.image1}></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LandingPage;