import React, { useEffect, useState } from "react";
import routes from "../../routes";
import { Link, useSearchParams } from 'react-router-dom';

import assessorApis from "../../api/assessor";
import store from "../../api/store";
import useApi from "../../hooks/useApi";
import { Button, Modal } from "react-bootstrap";
import styles from "../../styles/AssessmentBatch.module.css";
import logo from "../../images/LogoParodyWhite.svg";
import NavItem from "../../components/NavItem";
import { PuffLoader } from "react-spinners";
import { helpers } from "../../utils/helpers";
import { FaArrowLeft, FaEdit, FaTrash } from 'react-icons/fa';
import Wrapper from "../../layouts/Wrapper";


const AssessmentCandidates = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const appCode = searchParams.get("applicationCode");

    const [candidateName, setCandidateName] = useState();

    const [addCandidate, setAddCandidate] = useState();
    const addCandidateApi = useApi(assessorApis.addCandidate);
    const [updateCandidate, setUpdateCandidate] = useState();
    const updateCandidateApi = useApi(assessorApis.updateCandidate);
    const [candidateToDelete, setCandidateToDelete] = useState();
    const deleteCandidateApi = useApi(assessorApis.deleteCandidate);

    const getCandidatesApi = useApi(assessorApis.getCandidates);
    const [candidates, setCandidates] = useState([]);

    useEffect(() => {
        getCandidates();
    }, []);

    const getCandidates = async () => {
        const res = await getCandidatesApi.request(appCode);
        if (res.ok) setCandidates(res.data.data);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await addCandidateApi.request(candidateName);
        if (res.ok) {
            helpers.toaster.success(res.data.responseMessage);
            window.location.reload(false);
        }
    }

    // const handleUpdate = async (e) => {
    //     e.preventDefault();
    //     const res = await updateCandidateApi.request({name: candidateName.name, id: updateCandidate.id });
    //     if (res.ok) {
    //         helpers.toaster.success("Candidate Updated Successfully");
    //         window.location.reload(false);
    //     }
    // }

    const handleDelete = async (id) => {
        setCandidateToDelete(id);
        const del = window.confirm("Are you sure you wants to delete this Candidate?");
        if (del) {
            const res = await deleteCandidateApi.request(id);
            if (res.ok) {
                helpers.toaster.success("Candidate Deleted Successfully");
                window.location.reload(false);
            }
        }
    }


    return (
        <Wrapper activePage={routes.AssessmentCandidates}>
            <div className="row m-0 p-0">
                <div className="col-lg-3 text-start">
                    <h5><Link to={routes.AssessorDashboard} style={{ color: "#000" }}><FaArrowLeft /> Back to Dashboard </Link></h5>
                </div>
                <div className="col-lg-5 text-center">
                    <h2>Assessment Candidates</h2>
                </div>
                <div className="col-lg-4 text-end">
                    <button type="submit" onClick={() => setAddCandidate(true)} className={styles.sendCode}> Add New Candidate </button>
                </div>
            </div>


            <div className="row m-0 p-0">
                <div className="col-lg-10 m-auto">
                    <div className={styles.card1}>
                        <table>
                            <tr style={{ borderBottom: "1px solid grey" }}>
                                <td className={styles.left}><span>Candidate Name</span></td>
                                <td className={styles.left}><span>Status</span></td>
                                <td className={styles.left}><span>Action</span></td>
                            </tr>
                            {candidates.length === 0 && !getCandidatesApi.loading && <tr>No Candidates Available </tr>}
                            {getCandidatesApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                candidates.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left}><label>{item.name}</label></td>
                                    {/* <td className={styles.left}>{item.candidateBatches.map(candidates => <label>{candidates.name}</label>)}</td> */}
                                    <td className={styles.left}>{item.inBatch === true ? "Already added to a batch" : "Not yet added to a batch"}</td>
                                    <td className={styles.left}>
                                        {/* <button type="submit" onClick={() => setUpdateCandidate(item)} className={styles.success}> <FaEdit /> {updateCandidateApi.loading ? "Updating.." : "Update"} </button> */}
                                        <button type="submit" onClick={() => handleDelete(item.id)} className={styles.danger}> <FaTrash /> {candidateToDelete === item.id && deleteCandidateApi.loading ? "Deleting.." : "Delete"} </button>
                                    </td>
                                </tr>)
                            }
                        </table>
                    </div>
                </div>
            </div>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={addCandidate}
                onHide={() => setAddCandidate(null)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <form method="post" onSubmit={handleSubmit} className={styles.form}>
                        <h4 align="left" className="mb-5">Add new candidate</h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <label>Candidate Name</label>
                                <input type="text" id="" className="form-control" placeholder="Sam John" onChange={(e) => setCandidateName({ ...candidateName, name: e.target.value })}></input>
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <button type="submit" className={styles.sendCode}>{addCandidateApi.loading ? "Submiting..": "Submit"}</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            {/* <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={updateCandidate}
                onHide={() => setUpdateCandidate(null)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <form method="post" onSubmit={handleUpdate} className={styles.form}>
                        <h4 align="left" className="mb-5">Update Candidate</h4>
                        <div className="row">
                            <div className="col-lg-12">
                                <label>Candidate Name</label>
                                <input type="text" id="" className="form-control" placeholder="Sam John" onChange={(e) => setCandidateName({ ...candidateName, name: e.target.value })}></input>
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <button type="submit" className={styles.sendCode}>{updateCandidateApi.loading ? "Updating.." : "Update"}</button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> */}
        </Wrapper>
    )
};


export default AssessmentCandidates;