import * as Yup from "yup";

const accessCodeSchema = Yup.object().shape({
    first_name: Yup.string().required().label("First name"),
    last_name: Yup.string().required().label("Last name"),
    email: Yup.string().required().label("Email address"),
    mobile: Yup.string().required().label("Mobile"),
});

const loginSchema = Yup.object().shape({
    email: Yup.string().email().required().label("Email"),
    password: Yup.string().required().label("Password"),
});

const addGameSchema = Yup.object().shape({
    skill: Yup.array().label("Styles"),
    name: Yup.string().required().label("Name"),
    competency: Yup.array().label("Competency"),
    description: Yup.string().required().label("Description"),
});

const profileSchema = Yup.object().shape({
    //Surname: Yup.string().required().label("Surname"),
    Password: Yup.string().required().label("Password"),
    LastName: Yup.string().label("Other Name"),
    FirstName: Yup.string().required().label("FirstName"),
    CompanyName: Yup.string().required().label("Company name"),
    CompanyEmail: Yup.string().email().required().label("Email"),
    CompanyPhoneNumber: Yup.string().required().label("Phone number"),
    cpassword: Yup.string().required().label("Confirm new password").oneOf([Yup.ref("Password"), null], "Passwords must match"),

});

const resetPasswordSchema = Yup.object().shape({
    email: Yup.string().required().label("Email Address"),
    token: Yup.string().required().label("Token"),
    password: Yup.string().required().label("New Password"),
    confirmPassword: Yup.string().required().label("Confirm new password").oneOf([Yup.ref("password"), null], "Passwords must match"),

});

const assessmentSchema = Yup.object().shape({
    games: Yup.array().min(1, "Please select atleast 1 Game").required("Please select at max 3 games").label("Games"),
    dateOfEvent: Yup.string().required().label("Date of event"),
    timeOfEvent: Yup.string().required().label("Time of event")
});

const assessorProfileSchema = Yup.object().shape({
    LastName: Yup.string().label("Last Name"),
    Password: Yup.string().required().label("Password"),
    Email: Yup.string().email().required().label("Email"),
    FirstName: Yup.string().required().label("First Name"),
    CompanyName: Yup.string().required().label("Company Name"),
    PhoneNumber: Yup.string().required().label("Phone Number"),
    cpassword: Yup.string().required().label("Confirm new password").oneOf([Yup.ref("Password"), null], "Passwords must match"),

});

const editCompetencySchema = Yup.object().shape({
    name: Yup.string().required().label("Name"),
    description: Yup.string().required().label("Description"),
    basicText: Yup.string().required().label("Basic Text"),
    insufficientText: Yup.string().required().label("Insufficient Text"),
    goodText: Yup.string().required().label("Good Text"),
});

const editSkillSchema = Yup.object().shape({
    name: Yup.string().required().label("Name"),
    description: Yup.string().required().label("Description"),
    title1: Yup.string().required().label("Quadrant Name"),
    subText1: Yup.string().required().label("Sub Text"),
    primaryText1: Yup.string().required().label("Primary Text"),
    secondaryText1: Yup.string().required().label("Secondary Text"),
    title2: Yup.string().required().label("Quadrant Name"),
    subText2: Yup.string().required().label("Sub Text"),
    primaryText2: Yup.string().required().label("Primary Text"),
    secondaryText2: Yup.string().required().label("Secondary Text"),
    title3: Yup.string().required().label("Quadrant Name"),
    subText3: Yup.string().required().label("Sub Text"),
    primaryText3: Yup.string().required().label("Primary Text"),
    secondaryText3: Yup.string().required().label("Secondary Text"),
    title4: Yup.string().required().label("Quadrant Name"),
    subText4: Yup.string().required().label("Sub Text"),
    primaryText4: Yup.string().required().label("Primary Text"),
    secondaryText4: Yup.string().required().label("Secondary Text"),
    yaxisHigh: Yup.string().required().label("Y Axis High"),
    yaxisLow: Yup.string().required().label("Y Axis Low"),
    xaxisHigh: Yup.string().required().label("X Axis High"),
    xaxisLow: Yup.string().required().label("X Axis Low"),
});

const editQuestionaireSchema = Yup.object().shape({
    question: Yup.string().required().label("Question"),
    questionaireOptions: Yup.array().required().label("Statements"),
    competencyId: Yup.number().required().label("Choose Competency"),
});

const editStatementSchema = Yup.object().shape({
    skillName: Yup.string().required().label("Styles Name"),
    question: Yup.string().required().label("Question"),
    statement: Yup.string().required().label("Statement"),
    quadrantId1: Yup.string().required().label("Quadrant Name"),
    quadrantId2: Yup.string().required().label("Quadrant Name"),
    quadrantId3: Yup.string().required().label("Quadrant Name"),
    quadrantId4: Yup.string().required().label("Quadrant Name"),
});

const editAssessorSchema = Yup.object().shape({
    firstName: Yup.string().label("FirstName"),
    lastName: Yup.string().label("LastName"),
    companyName: Yup.string().label("Company Name"),
    phoneNumber: Yup.string().label("Phone Number"),
});

const editCompanyUserSchema = Yup.object().shape({
    firstName: Yup.string().label("FirstName"),
    lastName: Yup.string().label("LastName"),
    surname: Yup.string().label("SurName"),
    email: Yup.string().label("Email Address"),
});

const addNewUserSchema = Yup.object().shape({
    email: Yup.string().email().required().label("Email Address"),
    firstName: Yup.string().required().label("FirstName"),
    lastName: Yup.string().required().label("LastName"),
    password: Yup.string().required().label("Password"),
    companyId: Yup.string().required().label("Company Id"),
});

const validationSchemas = {
    loginSchema,
    addGameSchema,
    profileSchema,
    editSkillSchema,
    accessCodeSchema,
    assessmentSchema,
    editCompetencySchema,
    editQuestionaireSchema,
    editStatementSchema,
    editAssessorSchema,
    assessorProfileSchema,
    addNewUserSchema,
    resetPasswordSchema,
    editCompanyUserSchema,
};

export default validationSchemas;