import React, { useEffect, useState } from "react";
import routes from "../../routes";
import { Link, useSearchParams } from 'react-router-dom';

import assessorApis from "../../api/assessor";
import store from "../../api/store";
import useApi from "../../hooks/useApi";
import { Button, Modal } from "react-bootstrap";
import styles from "../../styles/AssessorDashboard.module.css";
import logo from "../../images/LogoParodyWhite.svg";
import NavItem from "../../components/NavItem";
import { PuffLoader } from "react-spinners";
import { helpers } from "../../utils/helpers";
import { FaArrowLeft, FaEdit, FaTrash } from 'react-icons/fa';
import Wrapper from "../../layouts/Wrapper";
import gamesIcon from "../../images/gamesIcon.png";
import { MultiSelect } from "react-multi-select-component";


const AssessmentBatches = () => {

    const [searchParams, setSearchParams] = useSearchParams();

    const appCode = searchParams.get("applicationCode");

    const [activePage, setActivePage] = useState("first");
    const [activePage1, setActivePage1] = useState("first");
    const [activePage0, setActivePage0] = useState("first");

    const [showAssessment, setShowAssessment] = useState();

    const [showCreate, setShowCreate] = useState();
    const [showUpdate, setShowUpdate] = useState();

    const [createBatch, setCreateBatch] = useState();
    const createBatchApi = useApi(assessorApis.createBatch);
    const [updateBatch, setUpdateBatch] = useState();
    const updateBatchApi = useApi(assessorApis.updateBatch);
    const [batchToDelete, setBatchToDelete] = useState();
    const deleteBatchApi = useApi(assessorApis.deleteBatch);

    const [selectedCandidates, setSelectedCandidates] = useState([]);

    const getBatchesApi = useApi(assessorApis.getBatches);
    const [batches, setBatches] = useState([]);

    const [batchId, setBatchId] = useState();
    const getBatchByIdApi = useApi(assessorApis.getBatchById);

    const getCandidatesApi = useApi(assessorApis.getCandidates);
    const [candidates, setCandidates] = useState([]);

    const [candidateName, setCandidateName] = useState();

    const [addCandidate, setAddCandidate] = useState();
    const addCandidateApi = useApi(assessorApis.addCandidate);

    const [assessmentBatch, setAssessmentBatch] = useState();
    const getAssessBatchApi = useApi(assessorApis.getAssessBatch);
    const getApplicationDetailApi = useApi(assessorApis.getApplicationDetail);

    const submitAssessedBatchApi = useApi(assessorApis.submitAssessedBatch);

    const autoSaveApi = useApi(assessorApis.autoSave);

    const [selectedBatch, setSelectedBatch] = useState();

    const deleteBatchCandidateApi = useApi(assessorApis.deleteBatchCandidate);

    const [candidateToDelete, setCandidateToDelete] = useState();
    const deleteCandidateApi = useApi(assessorApis.deleteCandidate);

    useEffect(() => {
        getBatches();
    }, []);

    const getBatches = async () => {
        const res = await getBatchesApi.request(appCode);
        if (res.ok) setBatches(res.data.data);
    }

    useEffect(() => {
        getCandidates();
    }, []);

    const getCandidates = async () => {
        const res = await getCandidatesApi.request(appCode);
        if (res.ok) setCandidates(res.data.data);
    }

    const handleSubmit1 = async (e) => {
        e.preventDefault();
        const candidateId = selectedCandidates.map(list => list.value);
        const res = await createBatchApi.request({ ...createBatch, candidateId: candidateId, applicationCode: appCode });
        if (res.ok) {
            helpers.toaster.success(res.data.responseMessage);
            window.location.reload(false);
        }

    }

    const handleAddCandidate = async (e) => {
        e.preventDefault();
        const res = await addCandidateApi.request({ ...candidateName, applicationCode: appCode});
        if (res.ok) {
            helpers.toaster.success(res.data.responseMessage);
            window.location.reload(false);
        }
    }

    const handleDelete = async (id) => {
        setBatchToDelete(id);
        const del = window.confirm("Are you sure you wants to delete this Batch?");
        if (del) {
            const res = await deleteBatchApi.request(id);
            if (res.ok) {
                helpers.toaster.success(res.data.responseMessage);
                window.location.reload(false);
            }
        }
    }

    const getBatchById = async (id) => {
        const res = await getBatchByIdApi.request(id);
        if (res.ok) {
            setBatchId(res.data.data);
            setShowUpdate(true);
        }
    }

    const handleStartAssessment = async (id) => {
        const res = await getAssessBatchApi.request({ applicationCode: appCode, batchId: id });
        if (res.ok) {
            const result = await getApplicationDetailApi.request({ applicationCode: appCode, batchId: id });
            if (result.ok) {setActivePage("second");}
        }
    }

    const handleAutoSave = async (candidateId) => {
        autoSaveApi.request({ ...candidateId, applicationCode: appCode });
    };

    const handleSubmit = async (data) => {
        const res = await submitAssessedBatchApi.request({ code: data.applicationCode, batchId: data.batchId });
        if (res.ok) {
            helpers.toaster.success("Batch Assessment submitted successfully");
            window.location.reload(false);
        }
    }

    const handleDeleteCandidate = async (e) => {
        e.preventDefault();
        const del = window.confirm("Are you sure you wants to delete this Candidate(s)?");
        const candidateId = selectedCandidates.map(list => list.value);
        if (del) {
            const res = await deleteBatchCandidateApi.request({ candidateId: candidateId, id: selectedBatch.id });
            if (res.ok) {
                helpers.toaster.success(res.data.responseMessage);
                window.location.reload(false);
            }
        }
    }

    const handleCandidateDelete = async (id) => {
        setCandidateToDelete(id);
        const del = window.confirm("Are you sure you wants to delete this Candidate?");
        if (del) {
            const res = await deleteCandidateApi.request(id);
            if (res.ok) {
                helpers.toaster.success("Candidate Deleted Successfully");
                window.location.reload(false);
            }
        }
    }

    const candidateOptions = candidates.map((item) => ({
        value: item.id, label: item.name
    }));

    const candidateDeleteOptions = batchId?.candidates?.map((item) => ({
        value: item.id, label: item.name
    }));

    

    return (
        <Wrapper activePage={routes.AssessmentBatches}>
            <div hidden={activePage === "second"}>
                <div className="row m-0 pb-4">
                    <div className="col-lg-3 text-start">
                        <h5><Link to={routes.AssessorDashboard} style={{color: "#000"}}><FaArrowLeft /> Back to Dashboard </Link></h5>
                    </div>
                    <div className="col-lg-4 text-center">
                        <h2>Application Batches</h2>
                    </div>
                    <div className="col-lg-5 text-end">
                        <button type="submit" onClick={() => setShowCreate(true)} className={styles.sendCode}> Add A Batch </button>
                        <button type="submit" onClick={() => setAddCandidate(true)} className={styles.sendCode}> Add Candidate </button>
                    </div>
                </div>


                <div className="row m-0 p-0">
                    {activePage0 === "first" && <div className="col-lg-10 m-auto">
                        <div style={{marginLeft: "50px", marginBottom: "-25px"}}>
                            <button onClick={() => setActivePage0("first")} className={styles.tabActive}>Batches</button>
                            <button onClick={() => setActivePage0("second")} className={styles.tab}>Candidates</button>
                        </div>
                        <div className={styles.card1}>
                            <table>
                                <tr style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left}><span>Batch Name</span></td>
                                    <td className={styles.left}><span>Candidates</span></td>
                                    <td className={styles.left}><span>Status</span></td>
                                    <td className={styles.left}><span>Action</span></td>
                                </tr>
                                {batches.length === 0 && !getBatchesApi.loading && <tr>No Batches Available </tr>}
                                {getBatchesApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    batches.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                        <td className={styles.left}><label>{item.name}</label></td>
                                        <td className={styles.left}><label>{item.candidates.map(candidate => <>{candidate.name}{item.candidates.length > 1 ? <>, &nbsp;&nbsp;</> : "."} </>)}</label></td>
                                        <td className={styles.left}><label>{item.status}</label></td>
                                        <td className={styles.left}>
                                            <button onClick={() => {getBatchById(item.id); setSelectedBatch(item)}} className={styles.danger}> <FaTrash /> {updateBatchApi.loading ? "Deleting.." : "Delete Batch Candidate"} </button>
                                            <button onClick={() => handleDelete(item.id)} className={styles.danger}> <FaTrash /> {batchToDelete === item.code && deleteBatchApi.loading ? "Deleting.." : "Delete Batch"} </button>
                                            <button to="" onClick={() => {handleStartAssessment(item.id); setActivePage("second")}} style={{ backgroundColor: "#27E34B", color: "#fff", border: "none", padding: "10px 30px", borderRadius: "20px" }}>{item.status === "Pending" ? "Start Assessment" : item.status === "Assessing" ? "Continue Assessment" : "Assessed"}</button>
                                        </td>
                                    </tr>)
                                }
                            </table>
                        </div>
                    </div>}
                    {activePage0 === "second" && <div className="col-lg-10 m-auto">
                        <div style={{ marginLeft: "50px", marginBottom: "-25px" }}>
                            <button onClick={() => setActivePage0("first")} className={styles.tab}>Batches</button>
                            <button onClick={() => setActivePage0("second")} className={styles.tabActive}>Candidates</button>
                        </div>
                        <div className={styles.card1}>
                            <table>
                                <tr style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left}><span>Candidate Name</span></td>
                                    <td className={styles.left}><span>Status</span></td>
                                    <td className={styles.left}><span>Action</span></td>
                                </tr>
                                {candidates.length === 0 && !getCandidatesApi.loading && <tr>No Candidates Available </tr>}
                                {getCandidatesApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    candidates.map((item, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                        <td className={styles.left}><label>{item.name}</label></td>
                                        <td className={styles.left}><label>{item.inBatch === true ? "Already added to a batch" : "Not yet added to a batch"}</label></td>
                                        <td className={styles.left}>
                                            <button type="submit" onClick={() => handleCandidateDelete(item.id)} className={styles.danger}> <FaTrash /> {candidateToDelete === item.id && deleteCandidateApi.loading ? "Deleting.." : "Delete"} </button>
                                        </td>
                                    </tr>)
                                }
                            </table>
                        </div>
                    </div>}
                </div>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showCreate}
                    onHide={() => setShowCreate(null)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <form method="post" onSubmit={handleSubmit1} className={styles.form}>
                            <h4 align="left" className="mb-5">Add New Batch</h4>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label>Batch Name</label>
                                    <input type="text" id="" className="form-control" placeholder="Enter batch name" onChange={(e) => setCreateBatch({ ...createBatch, name: e.target.value })}></input>
                                </div>
                                <div className="col-lg-12 mt-4">
                                    <label>Select Candidate(s)</label>
                                    <MultiSelect
                                        isLoading={getCandidatesApi.loading}
                                        //shouldToggleOnHover
                                        options={candidateOptions}
                                        className={styles.filter}
                                        value={selectedCandidates}
                                        labelledBy={"Filter by Styles"}
                                        onChange={setSelectedCandidates}
                                    />
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <button type="submit" className={styles.sendCode}>{createBatchApi.loading ? "Submiting" : "Submit"}</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showUpdate}
                    onHide={() => setShowUpdate(null)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <form method="post" onSubmit={handleDeleteCandidate} className={styles.form}>
                            <h4 align="left" className="mb-5">Delete Batch Candidate</h4>
                            <div className="row">
                                <div className="col-lg-12 mt-4">
                                    {/* <div className="mt-3 mb-3">{batchId?.candidates?.map(list =>
                                        <p style={{ textAlign: "left", border: "solid 1px #e0e0e0", margin: "5px", padding: "0px 10px", display: "inline-block", width: "48%" }}>
                                            <button hidden className="btn btn-danger" onClick={() => handleDeleteCandidate(list.Id, showUpdate.id)}>Delete </button>  <label>{list.name}</label>
                                        </p>)}
                                    </div> */}
                                    <label>Select Candidate(s)</label>
                                    <MultiSelect
                                        isLoading={getBatchByIdApi.loading}
                                        options={candidateDeleteOptions}
                                        className={styles.filter}
                                        value={selectedCandidates}
                                        labelledBy={"Filter by Styles"}
                                        onChange={setSelectedCandidates}
                                    />
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <button type="submit" className={styles.sendCode}>{deleteBatchCandidateApi.loading ? "Submiting" : "Submit"}</button>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={addCandidate}
                    onHide={() => setAddCandidate(null)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="text-center">
                        <form method="post" onSubmit={handleAddCandidate} className={styles.form}>
                            <h4 align="left" className="mb-5">Add new candidate</h4>
                            <div className="row">
                                <div className="col-lg-12">
                                    <label>Candidate Name</label>
                                    <input type="text" id="" className="form-control" placeholder="Sam John" onChange={(e) => setCandidateName({ ...candidateName, name: e.target.value })}></input>
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <button type="submit" className={styles.sendCode}>{addCandidateApi.loading ? "Submiting.." : "Submit"}</button>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </div>

            <div hidden={activePage === "first"}>
                <div className="row m-0 p-0">
                    <div className="col-lg-4 text-start">
                        <h5><button type="button" onClick={() => setActivePage("first")} style={{ color: "#000", border: "none", backgroundColor: "transparent" }}><FaArrowLeft /> Back to Dashboard </button></h5>
                    </div>
                    <div className="col-lg-4 text-start">
                        <h2 align="center">Assessment Test </h2>
                    </div>
                </div>

                {getApplicationDetailApi.loading ? <div className={styles.loader}><PuffLoader loading={true} /></div> :
                getApplicationDetailApi.data.map((games, key) =>
                    <div key={key}>
                        <div className="row m-0 p-0">
                            <div className="col-lg-12 m-auto">
                                <div className={styles.card}>
                                    <div className="row">
                                        <div className="col-lg-2 m-auto">
                                            <img src={gamesIcon} width="150px" alt=""></img>
                                        </div>
                                        <div className="col-lg-6 m-0 p-0">
                                            <h4 className={styles.gameTitle}>
                                                {games.gameName}
                                            </h4>
                                            <p className={styles.description}>
                                                {games.gameDescription}
                                            </p>
                                        </div>
                                        <div className="col-lg-4 text-center m-auto">
                                            <h5 className={styles.gameTitle}>
                                                {games.batch.name}<br />
                                                {games.batch?.candidates?.length}{" "}
                                                Candidate(s)
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <br></br>

                                <div hidden={activePage1 !== "first"}>
                                    <button onClick={() => setActivePage1("first")} className={styles.name}>Styles<span className={styles.small}>Styles</span></button>
                                    <button onClick={() => setActivePage1("second")} className={styles.nameActive}>Competencies <span className={styles.small}>Competencies</span></button>
                                    <div className={styles.card2}>
                                        {games.skills.map((skill, key) => (
                                            <table width={"100%"} key={key} className="mb-5">
                                                <thead>
                                                    <tr><h4><b>{skill.skillName}</b></h4></tr>
                                                </thead>
                                                <thead>
                                                    <td width={"40%"}>Question</td>
                                                    <td width={"40%"}>Options</td>
                                                    {games.batch?.candidates?.map((candidate, key) => (<td key={key} width={"20%"}>{candidate.name}</td>))}
                                                </thead>

                                                <tr>
                                                    <td colSpan={games.batch?.candidates?.length + 2}><hr></hr></td></tr>

                                                {skill.statement.map((statement) => (
                                                    statement.skillStatementOptions.map((item, key) => <>
                                                        {key === 0 && <thead><td colSpan={0} >{statement.statementTitle}</td></thead>} {/* only show this once  */}
                                                        <tr className={styles.contents} key={key}>
                                                            <td></td>
                                                            <td>{item.statementOptionTitle}</td>
                                                            {games.batch?.candidates?.map(
                                                                (candidate, key) => (
                                                                    <td key={key}>
                                                                        <input type="checkbox" style={{ width: 100, height: 20 }}
                                                                            onChange={(e) => handleAutoSave({
                                                                                batchId: games.batch.batchId,
                                                                                candidateId: candidate.candidateId, applicationSkillId: skill.applicationSkillId,
                                                                                statementId: statement.statementId,
                                                                                quadrantId: item.quadrantId,
                                                                                statementOptionId: item.statementOptionId,
                                                                                statementOptionValue: e.target.checked
                                                                            })}
                                                                        />
                                                                    </td>
                                                                )
                                                            )}
                                                        </tr></>)
                                                ))}
                                            </table>
                                        )
                                        )}
                                    </div>
                                </div>

                                <div hidden={activePage1 !== "second"}>
                                    <button onClick={() => setActivePage1("first")} className={styles.nameActive}>Styles <span className={styles.small}>Styles</span></button>
                                    <button onClick={() => setActivePage1("second")} className={styles.name}>Competencies <span className={styles.small}>Competencies</span></button>
                                    <div className={styles.card2}>
                                        {games.competencies.map((competency, key) => (
                                            <table width={"100%"} key={key} className="mb-5">
                                                <thead>
                                                    <tr><h4><b> {competency.competencyName}</b></h4></tr>
                                                </thead>
                                                <thead>
                                                    <td width={"40%"}>Question</td>
                                                    <td width={"40%"}>Options</td>
                                                    {games.batch?.candidates?.map((candidate, key) => (<td key={key} width={"20%"}>{candidate.name}</td>))}
                                                </thead>
                                                <tr><td colSpan={games.batch?.candidates?.length + 2}><hr></hr></td></tr>
                                                {competency.questionaireStatement.map((questionaireStatement) => (
                                                    questionaireStatement.questionaireResponseOptions.map((responseOption, key) => <>
                                                        {key === 0 && <thead><td colSpan={0} >{questionaireStatement.question}</td></thead>} {/* only show this once  */}
                                                        <tr key={key} className={styles.contents}>
                                                            <td></td>
                                                            <td>{responseOption.title}</td>
                                                            {games.batch?.candidates?.map((candidate, key) => (
                                                                <td key={key}>
                                                                    <input type="checkbox" style={{ width: 100, height: 20 }}
                                                                        onChange={(e) => handleAutoSave({
                                                                            batchId: games.batch.batchId,
                                                                            candidateId: candidate.candidateId,
                                                                            questionaireOptionId: responseOption.id,
                                                                            questionaireOptionValue: e.target.checked,
                                                                            questionaireId: questionaireStatement.questionaireId,
                                                                            applicationCompetencyId: competency.applicationCompetencyId,
                                                                        })}
                                                                    />
                                                                </td>
                                                            )
                                                            )}
                                                        </tr></>)
                                                ))}
                                            </table>
                                        )
                                        )}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                )}

                <div className="row m-0 p-0">
                    <div className="col-lg-12 m-auto">
                        <div className="text-center mt-3 mb-5">
                            <button onClick={() => handleSubmit({ batchId: getApplicationDetailApi.data[0].batch.batchId, applicationCode: appCode })} className={styles.sendCode}>
                                {submitAssessedBatchApi.loading ? "SUBMITTING..." : "SUBMIT"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            
        </Wrapper>
    )
};

export default AssessmentBatches;