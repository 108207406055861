import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import routes from "../../routes";
import { Link } from 'react-router-dom';
import useApi from "../../hooks/useApi";
import schemas from "../../utils/schemas";
import homeBg from "../../images/img1.svg";
import Loader from "../../components/Loader";
import { helpers } from "../../utils/helpers";
import recruit from "../../images/recruit.svg";
import logo from "../../images/LogoParody.svg";
import cadminApis from "../../api/cadmin";
import TextField from "../../components/form/TextField";
import styles from "../../styles/RequestCode.module.css";
import { Form, SubmitButton } from "../../components/form";

const RequestCode = () => {
    const navigate = useNavigate();
    const accessCodeApi = useApi(cadminApis.accessCode);

    const handleSubmit = async (data) => {
        accessCodeApi.setLoading(true);
        await accessCodeApi.request(data);
        accessCodeApi.setLoading(false);

        if(!accessCodeApi.error) return navigate('/company-admin/request-status');
        helpers.toaster.success("Questionaire Updated Successfully");
        window.location.reload(false);

        accessCodeApi.setLoading(false);
    }

    /////////////////////////////////////////////////////////////////////
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <Loader loading={accessCodeApi.loading} />
            <div className="row m-0 p-0">
                <div className="col-lg-4 p-0">
                    <div style={{ height: `${screenSize.dynamicHeight}px` }}>
                        <header className={styles.header}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={logo} className={styles.logo} alt="logo"></img>
                                </div>
                                <div className="col-md-6 text-right">
                                    <p className={styles.lang}><Link to="#" className={styles.link}><b>EN</b></Link>&nbsp;&nbsp;&nbsp;&nbsp;NL &nbsp;&nbsp; FR</p>
                                </div>
                            </div>
                        </header>
                        <div className={styles.content}>
                            <h2 align="center" className="mt-0 pt-0">Request an access code</h2>
                            <div className="mb-3 text-center">Already registered? <a href={routes.CompanyAdminLogin}>Login Here</a></div>
                            <h6 className={styles.error}>{accessCodeApi.error && "An error countered, please try again."}</h6>
                            <Form
                                validationSchema={schemas.accessCodeSchema}
                                initialValues={helpers.getInitialValuesFromSchema(
                                    schemas.accessCodeSchema
                                )}
                                onSubmit={handleSubmit}
                            >
                                <div className={styles.form}>
                                    <TextField name={"first_name"} label={"First Name"} className={styles.input} />
                                    <TextField name={"last_name"} label={"Last Name"} className={styles.input} />
                                    <TextField name={"email"} type={"email"} label={"Email Address"} className={styles.input} />  
                                    <TextField name={"mobile"} type={"tel"} label={"Mobile"} className={styles.input} />  
                                    <div className="text-center">
                                        <SubmitButton title={!accessCodeApi.loading ? "REQUEST" : "PROCESSING..."} />
                                        <div className={styles.text}>Already registered? <a href={routes.CompanyAdminLogin}>login</a></div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <footer className={styles.footer}>
                            <p>© 2022 PARODy platform is powered by Pilipili Games</p>
                        </footer>
                    </div>
                </div>
                <div className="col-lg-8 p-0">
                    <div className={styles.right}>
                        <div>
                            <img src={recruit} alt="logo" className={styles.logo1}></img>
                        </div>
                        <h2 className={styles.subtitle}>Serious Recruitment Games</h2>
                        <div>
                            <img src={homeBg} alt="people playing" className={styles.image1}></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default RequestCode;