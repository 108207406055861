import React, { useEffect, useState } from "react";

import routes from "../../routes";
import store from "../../api/store";
import useApi from "../../hooks/useApi";
import adminApis from "../../api/admin";
import schemas from "../../utils/schemas";
import Modal from "../../components/Modal";
import { PuffLoader } from "react-spinners";
import Wrapper from "../../layouts/Wrapper";
import { MDBSwitch } from "mdb-react-ui-kit";
import { helpers } from "../../utils/helpers";
import ShowMoreText from "react-show-more-text";
import { FaEdit, FaTrash } from 'react-icons/fa';
import gamesIcon from "../../images/gamesIcon.png";
import styles from "../../styles/Games.module.css";
import SelectField from "../../components/form/SelectField";
import { Form, SubmitButton, TextField } from "../../components/form";


const GamesPage = () => {

    store.setItem("activeTab", JSON.stringify("first"));
    const [games, setGames] = useState([]);
    const [competency, setCompetency] = useState([]);
    const [skill, setSkill] = useState([]);
    const [editGame, setEditGame] = useState();
    const addGameApi = useApi(adminApis.addGame);
    const getGamesApi = useApi(adminApis.getGames);
    const updateGameApi = useApi(adminApis.updateGame);
    const deleteGameApi = useApi(adminApis.deleteGame);
    const getSkillApi = useApi(adminApis.getSkillInfo);
    const [gameToDelete, setGameToDelete] = useState();
    const getCompetencyApi = useApi(adminApis.getCompetencyInfo);
    const [modalVisible, setModalVisible] = useState(false);
    const [addNewGame, setAddNewGame] = useState();
    const [fileToUpload, setFileToUpload] = useState();
    const [selectedSkill, setSelectedSkill] = useState([]);
    const [isReadMore, setIsReadMore] = useState(null);
  const toggleReadMore = (key) => {
    if (isReadMore === key) {
        setIsReadMore(null);
    }
    else {
        setIsReadMore(key)
    }
  };

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        getSkillApi.setLoading(true);
        await getSkillApi.request();
        await getCompetencyApi.request();
        const res = await getGamesApi.request();
        if(res.ok) setGames(res.data.data);
        getSkillApi.setLoading(false);
    }

    const getGame = (code) => games.find(game => game.code === code);
    

    const onFileChange = (e) => {

        const upload = e.target.files[0];
        setFileToUpload(upload);
    }


    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     console.log(addNewGame, fileToUpload);
    //     addGameApi.setLoading(true);
    //     const res = await addGameApi.request({ ...addNewGame, logo: fileToUpload});
    //     if (res.ok) {
    //             helpers.toaster.success("New Game Success Created");
    //             window.location.reload(false);
    //     }
    //     return addGameApi.setLoading(false);
    // }

    const handleSubmit = async (data) => {
        addGameApi.setLoading(true);

        if(!editGame) {
            const res = await addGameApi.request({...data, Logo: fileToUpload});
            
            // created game response data
            const game = {
                gameCompetencies: [],
                gameSkills: [],
                code: "dsoskwle",
                ...data
            }
            
            if(res.ok) {
                setGames([...games, game]);
                setModalVisible(false);
                helpers.toaster.success("Success Created");
                window.location.reload(false);
            }
            //return addGameApi.setLoading(false);
        }

        const res = await updateGameApi.request({...data, code: editGame, Logo: fileToUpload});
        const game = {
            gameCompetencies: [],
            gameSkills: [],
            code: "dsoskwle",
            ...data
        }
        
        if(res.ok) {
            const gameIndex = games.findIndex(game => game.code === editGame);
            const newGames = games;
            newGames[gameIndex] = game;
            setGames(newGames);
            helpers.toaster.success("Success Updated");
            window.location.reload(false);
        }
        addGameApi.setLoading(false);
    }

    const deleteGameSkillApi = useApi(adminApis.deleteGameSkill);
    const deleteGameCompetencyApi = useApi(adminApis.deleteGameCompetency);

    const handleGameSkillDelete = async (skillID, gameCode) => {
        // get the current game
        const game = games.filter((game) => game.code === gameCode)[0];

        // get the game index in the array
        const gameIndex = games.findIndex((game) => game.code === gameCode);
        // remove the skill to be deleted from the list of skills in the game
        const newSkills = game.skill.filter(skill => skill.id !== skillID);

        // create a new games array without the deleted skill
        const newGames = [
            ...games.filter(game => game.code !== gameCode),
            {
                ...games[gameIndex],
                skill: newSkills
            }
        ];
        // update the state
        setGames(newGames);

        // delete the actual game from the backend
        deleteGameSkillApi.request(skillID);

        // show success
        helpers.toaster.success("Success Deleted");
    }


    // const handleGameSkillDelete = async (id) => {
    //     const res = await deleteGameSkillApi.request(id);
    //     if (res.status === 422) helpers.toaster.error(res.data.responseMessage);
    //     if (res.ok) {
    //         helpers.toaster.success("Success Deleted");
    //     }
    // }

    const handleGameCompetencyDelete = async (competencyID, gameCode) => {
        // get the current game
        const game = games.filter((game) => game.code === gameCode)[0];

        // get the game index in the array
        const gameIndex = games.findIndex((game) => game.code === gameCode);

        // remove the skill to be deleted from the list of skills in the game
        const newCompetences = game.competency.filter(competency => competency.id !== competencyID);

        // create a new games array without the deleted skill
        const newGames = [
            ...games.filter(game => game.code !== gameCode),
            {
                ...games[gameIndex],
                competency: newCompetences
            }
        ];

        // update the state
        setGames(newGames);

        // delete the actual game from the backend
        deleteGameCompetencyApi.request(competencyID);

        // show success
        helpers.toaster.success("Success Deleted");
        
    }

    // const handleGameCompetencyDelete = async (id) => {
    //     const res = await deleteGameCompetencyApi.request(id);
    //     if (res.status === 422) helpers.toaster.error(res.data.responseMessage);
    //     if (res.ok) {
    //         helpers.toaster.success("Success Deleted");
    //     }

    // }

    const handleDelete = async (code) => {
        setGameToDelete(code);
        const del = window.confirm("Are you sure you wants to delete this game?");
        if(del) {
            const res = await deleteGameApi.request(code);
            // if(!res.ok) return
    
            const newGames = games.filter(game => game.code !== code);
            setGames(newGames);
            setGameToDelete(null);
            helpers.toaster.success("Success Deleted");
        }
    }

    const executeOnClick = () => { }

    const disableGameStatusApi = useApi(adminApis.disableGameStatus);
    const enableGameStatusApi = useApi(adminApis.enableGameStatus);

    const getGameDisabledApi = useApi(adminApis.getGameDisabled);
    const [gameDisabled, setGameDisabled] = useState([]);

    useEffect(() => {
        getGameDisabled();
    }, []);

    const getGameDisabled = async () => {
        const res = await getGameDisabledApi.request();
        if (res.ok) setGameDisabled(res.data.data);
    }

    const handleGameDisable = async (code) => {
        const res = await disableGameStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Game Disabled Successfully");
            window.location.reload(false);
        }
    }

    const handleGameEnable = async (code) => {
        const res = await enableGameStatusApi.request(code);

        if (res.ok) {
            helpers.toaster.success("Game Enabled Successfully");
            window.location.reload(false);
        }
    }
    
    return (
        <Wrapper activePage={routes.GamesPage}>
            <div className="row m-0 p-0">
                <div className="col-lg-6"><h2 align="right">Games</h2></div>
                <div className="col-lg-6"><button onClick={() => setModalVisible(true)} className={styles.addGame}>+&nbsp;&nbsp;&nbsp;&nbsp; ADD NEW GAME</button></div>
                <Modal 
                    title="Add New Game"
                    show={modalVisible || editGame}
                    onHide={() => { if(!addGameApi.loading) { setEditGame(null); setModalVisible(false) }}}
                >
                    <Form
                        enableReinitialize
                        validationSchema={schemas.addGameSchema}
                        initialValues={editGame ? {
                            name: getGame(editGame)?.name,
                            description: getGame(editGame)?.description,
                            competency: [],
                            skill: [],
                        } 
                        : helpers.getInitialValuesFromSchema(schemas.addGameSchema)}
                        onSubmit={handleSubmit}
                    >
                        <div className="row mx-4">
                            <div className="col-lg-12 mb-4">
                                <TextField name={"name"} label={"Game Name"} className={styles.input}/>
                            </div>
                            <div className="col-lg-12 mb-4">
                                <TextField textarea name={"description"} label={"Game Description"} className={styles.input}/>
                            </div>
                            <div className="col-lg-12 mb-2 text-start">
                                <p align="left">Competencies</p> 
                                {getGame(editGame)?.competency?.map((compet, key) =>
                                     <p align="left" style={{ textAlign: "left", border: "solid 1px #e0e0e0", margin: "0px 20px 10px 0px", padding: "0px 20px", display: "inline-block" }} key={key}><span>{compet.name}</span>&nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-danger text-end" onClick={() => handleGameCompetencyDelete(compet.id, editGame)}>Delete Competency</button>
                                    </p>)}
                            </div>
                            <div className="col-lg-12 mb-4">
                                <SelectField items={getCompetencyApi.data} labelField={"name"} valueField={"id"} multiple name={"competency"} className={styles.input}/>
                            </div>
                            <div className="col-lg-12 mb-2 text-start">
                                <p align="left">Styles</p>
                                {getGame(editGame)?.skill?.map((skills, key) =>
                                    <p style={{ textAlign: "left", border: "solid 1px #e0e0e0", margin: "0px 20px 10px 0px", padding: "0px 20px", display: "inline-block" }} key={key}><span>{skills.name}</span>&nbsp;&nbsp;&nbsp;
                                        <button className="btn btn-danger text-end" onClick={() => handleGameSkillDelete(skills.id, editGame)}>Delete Style</button>
                                    </p>)}
                            </div>
                            <div className="col-lg-12 mb-4">
                                <SelectField items={getSkillApi.data} labelField={"name"} valueField={"id"} multiple name={"skill"}  className={styles.input}/>
                            </div>
                            <div className="col-lg-12 mb-4">
                                <input type="file" id="" onChange={onFileChange} className={styles.input}></input>
                            </div>
                        </div>
                        {addGameApi.error && <span className={styles.error}>An error occurred while trying to add a Game</span>}
                        <div className="text-center mt-4">
                            <SubmitButton title={addGameApi.loading ? editGame ? "Updating..." : "Adding Game..." : editGame ? "Update" : "Submit"} disabled={addGameApi.loading} className={styles.sendCode} />
                        </div>
                    </Form>
                </Modal>
            </div>

            <div className="row col-lg-11 m-auto mt-2 mb-5">
                <div className={styles.card0}>
                    <table style={{ marginTop: "20px", width: "100%" }}>
                        <tr style={{ borderBottom: "1px solid grey" }}>
                            <td className={styles.left}><span>Game Picture</span></td>
                            <td className={styles.left}><span>Game Name</span></td>
                            <td className={styles.left}><span>Game Description</span></td>
                            {/* <td className={styles.left}><span>Game Styles</span></td>
                            <td className={styles.left}><span>Game Competencies</span></td> */}
                            <td className={styles.left}><span>Action</span></td>
                        </tr>
                        {games.length === 0 && !getGamesApi.loading && <tr>No Games available </tr>}
                        {getGamesApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                            games.map((game, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                <td className={styles.left}>{game.gameLogo === null ? <img src={gamesIcon} alt="" width="80px"></img> : <img src={game.gameLogo} alt="" width="80px" loading="lazy"></img>} </td>
                                <td className={styles.left}><label>{game.name}</label></td>
                                <td className={styles.left}>
                                    <label style={{ width: "300px" }}>{isReadMore !== game.id ? `${game.description.slice(0, 100)}...` : game.description} <br/> 
                                    <span onClick={()=> toggleReadMore(game.id)} className="read-or-hide">{isReadMore === game.id ? "...read more" : " show less"}</span></label>
                                </td>
                                <td className={styles.left}>
                                    <button type="submit" onClick={() => setEditGame(game.code)} className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" disabled={deleteGameApi.loading} onClick={() => handleDelete(game.code)} className={styles.delete}> <FaTrash /> {gameToDelete === game.code && deleteGameApi.loading ? "Deleting.." : "Delete"} </button>
                                    <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch defaultChecked id="flexSwitchCheckChecked" onClick={() => handleGameDisable(game.code)} label={disableGameStatusApi.loading ? "Disabling.." : "Enabled"} style={{ fontSize: "20px" }} /></button>
                                    </td>
                            </tr>)
                        }

                        <tr className="mt-5"><td colSpan={4}><br></br><h5>Disabled Games</h5></td><hr></hr></tr>

                        {gameDisabled.length === 0 && !getGameDisabledApi.loading && <tr>No Disabled Games available </tr>}
                        {getGameDisabledApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                            gameDisabled.map((game, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                <td className={styles.left}><img src={gamesIcon} alt="" width="80px"></img></td>
                                <td className={styles.left}><label>{game.name}</label></td>
                                <td className={styles.left}>
                                    <label style={{ width: "300px" }}>{game.description}</label>
                                </td>
                                {/* <td className={styles.left}><label>{game.skill.map((skill, key) => <font key={key}>{skill.name}, </font> )}</label></td>
                                <td className={styles.left}><label>{game.competency.map((competency, key) => <font key={key}>{competency.name}, </font>)}</label></td> */}
                                <td className={styles.left}>
                                    <button type="submit" onClick={() => setEditGame(game.code)} className={styles.edit}> <FaEdit /> Edit </button> &nbsp;&nbsp;<button type="submit" disabled={deleteGameApi.loading} onClick={() => handleDelete(game.code)} className={styles.delete}> <FaTrash /> {gameToDelete === game.code && deleteGameApi.loading ? "Deleting.." : "Delete"} </button>
                                    <button style={{ border: "none", borderRadius: "30px", cursor: "help", backgroundColor: "#E0E0E0", marginLeft: "20px", padding: "8px 20px" }}><MDBSwitch id="flexSwitchCheckChecked" onClick={() => handleGameEnable(game.code)} label={enableGameStatusApi.loading ? " Enabling.." : "Disabled"} style={{ fontSize: "20px" }} /></button>
                                </td>
                            </tr>)
                        }
                    </table>
                </div>
            </div>
        </Wrapper>
    )
};

export default GamesPage;