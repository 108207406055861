import React, { useEffect, useState } from "react";

import cadminApis from "../../api/cadmin";
import useApi from "../../hooks/useApi";
import Wrapper from "../../layouts/Wrapper";
import { PuffLoader } from "react-spinners";
import { helpers } from "../../utils/helpers";
import { FaUser, FaPlusCircle } from 'react-icons/fa';
import { Form, SubmitButton, TextField } from "../../components/form";
import schemas from "../../utils/schemas";
import styles from "../../styles/UserProfile.module.css";
import routes from "../../routes";


const CreateUser = () => {

    const user = JSON.parse(localStorage.getItem("user"))

    const addNewUserApi = useApi(cadminApis.addNewUser);

    const handleSubmit = async (data) => {
        
        addNewUserApi.setLoading(true);

        const result = await addNewUserApi.request({ ...data });
        if (result.ok) {
            helpers.toaster.success("New User Created Successfully");
            window.location.reload(false);
        }
        //return navigate(routes.);
        addNewUserApi.setLoading(false);
    }

    return (
        <Wrapper activePage={routes.CreateUser}>
            <h2 align="center">Create New User</h2>
            <div className="row m-0 p-5">
                <div className="col-md-8 m-auto">
                    <div className={styles.card}>
                        <Form
                            enableReinitialize
                            validationSchema={schemas.addNewUserSchema}
                            initialValues={{
                                email: "",
                                firstName: "",
                                lastName: "",
                                password: "",
                                companyId: user.companyId,
                            }}
                            onSubmit={handleSubmit}
                        >
                            <div className="row">
                                <div className="col-lg-6">
                                    <TextField name={"email"} required label={"Email Address"} className={styles.input} />
                                </div>
                                <div className="col-lg-6">
                                    <TextField name={"firstName"} label={"FirstName"} className={styles.input} />
                                </div>
                                <div className="col-lg-6">
                                    <TextField name={"lastName"} label={"LastName"} className={styles.input} />
                                </div>
                                <div className="col-lg-6">
                                    <TextField name={"password"} label={"Password"} className={styles.input} />
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <SubmitButton title={addNewUserApi.loading ? "SUBMITING..." : "SUBMIT"} className={styles.sendCode} />
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};

export default CreateUser;