import React from "react";
import { useFormikContext } from "formik";
// import styles from "../../styles/form/submit.module.css";

const SubmitButton = ({ title, className, ...props }) => {
  const { handleSubmit } = useFormikContext();

  return <button {...props} type="submit" onClick={handleSubmit} className={className || "btn btn-dark px-5 py-5 pt-3 pb-3"}>{title}</button>;
};

export default SubmitButton;
