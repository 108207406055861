import React, { useState } from "react";
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import styles from "../../styles/form/textfield.module.css";

const TextField = ({
  name,
  label,
  type,
  style,
  className,
  textarea,
  secureEntry = false,
  ...otherProps
}) => {
  const [passwordHidden, setPasswordHidden] = useState(secureEntry);
  const { setFieldTouched, setFieldValue, errors, touched, values } = useFormikContext();

  return (
    <div className={styles.container}>
      <div className={styles.labelContainer}>
        <label className={styles.label}>{label}</label>
        {touched[name] && <span className={styles.error}>{errors[name]}</span>}
      </div>
      <div className={styles.fieldContainer}>
        {textarea ? <textarea 
          width={"50%"}
          className={className || styles.field}
          onBlur={() => setFieldTouched(name)}
          onChange={(e) => setFieldValue(name, e.target.value)}>{values[name]}</textarea> 
          : 
          <input
          width={"50%"}
          {...otherProps}
          value={values[name]}
          className={className || styles.field}
          onBlur={() => setFieldTouched(name)}
          type={passwordHidden ? "password" : type ? type : "text"}
          onChange={(e) => setFieldValue(name, e.target.value)}
        />}
        {secureEntry && (
          <FontAwesomeIcon
            color={"rgba(0,0,0,0.4)"}
            title="See password"
            className={styles.icon}
            icon={passwordHidden ? faEye : faEyeSlash}
            onClick={() => setPasswordHidden(!passwordHidden)}
          />
        )}
      </div>
    </div>
  );
};

export default TextField;