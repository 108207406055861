import client from "./client";

const getApplications = () => client.get("/api/AssessorProfile/Applications");

const updateProfile = (data) => {

  const formData = new FormData();
  formData.append("FirstName", data.FirstName);
  formData.append("Email", data.Email);
  formData.append("LastName", data.LastName);
  formData.append("Password", data.Password);
  formData.append("CompanyName", data.CompanyName);
  formData.append("PhoneNumber", data.PhoneNumber);
  formData.append("image", data.image);

  return client.post(`/api/AssessorProfile/${data.code}/CompleteAssessorProfile/`, formData);
};

const editAssessor = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));

  const input = {
    firstName: data.firstName,
    lastName: data.lastName,
    companyName: data.companyName,
    phoneNumber: data.phoneNumber
  };


  return client.post(`/api/AssessorProfile/${user.code}/UpdateAssessor`, input);
};

const getApplicationDetail = (data) =>
client.get(`/api/AssessorProfile/${data.batchId}/Application/${data.applicationCode}`);

const getAssessBatch = (data) => { 
  return client.post(`/api/AssessorProfile/${data.applicationCode}/AssessBatch/${data.batchId}`)
}

const autoSave = (data) => {

  const input = {
    "action": "Add",
    "candidateId": data.candidateId === undefined ? null : data.candidateId,
    "batchId": data.batchId,
    "skill": data.applicationSkillId === undefined ? null : {
      "applicationSkillId": data.applicationSkillId,
      "quadrantId": data.quadrantId,
      "statementOptionValue": data.statementOptionValue,
      "statementOptionId": data.statementOptionId
    },
    "competency": data.applicationCompetencyId === undefined ? null : {
      "applicationCompetencyId": data.applicationCompetencyId,
      "questionaireId": data.questionaireId,
      "questionaireOptionId": data.questionaireOptionId,
      "questionaireOptionValue": data.questionaireOptionValue
    }
  }

  return client.post(`/api/AssessorProfile/autosave/${data.applicationCode}`, input);
}

const getAutoSaveData = (data) => client.post(`/api/AssessorProfile/${data.batchId}/getsaved/${data.applicationCode}`)

const getSkillReport = (data) =>
  client.get(`/api/AssessorProfile/Report/${data.code}/skill/${data.batchId}`);
  //client.get(`/api/AssessorProfile/Report/${code}/skill`);

const getCompetencyReport = (data) =>
  client.get(`/api/AssessorProfile/Report/${data.code}/competency/${data.batchId}`);
  //client.get(`/api/AssessorProfile/Report/${code}/competency`);

const submitAssessedBatch = (data) => {
  console.log(data);
  return client.post(`/api/AssessorProfile/${data.code}/batchcompleted/${data.batchId}`);
};

const submitParticipant = (data) => {
  const input = { "participantName": data.participants }
  return client.post(`/api/AssessorProfile/Application/${data.code}/Participant`, input);
};

const uploadImage = (data) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const profileImage = data;

  const formData = new FormData();
  formData.append("image", profileImage);

  return client.post(`/api/AssessorProfile/updateimage/${user.code}`, formData);
};

const forgotPassword = (data) => client.post("/api/AssessorProfile/ForgotPassword", data);

const resetPassword = (data) => client.post("/api/AssessorProfile/ResetPassword", data);

const getAllCandidates = () => client.get(`/api/Candidate/getall`);

const getCandidates = (code) => client.get(`/api/Candidate/get-by-application/${code}`);

const addCandidate = (data) => client.post(`/api/Candidate/create-candidate`, data);

const updateCandidate = (data) => {
  
  const input = {"name": data.name};

  return client.post(`/api/Candidate/update-candidate/${data.id}`, input);
}

const deleteCandidate = (id) => client.post(`/api/Candidate/delete-candidate/${id}`);

const getBatches = (code) => client.get(`/api/Batch/get-by-application/${code}`);

const createBatch = (data) => { 

  console.log(data);

  return client.post(`/api/Batch/create-batch`, data);
}

const updateBatch = (data) => client.post(`/api/Batch/update-batch`, data);

const deleteBatch = (id) => client.post(`/api/Batch/delete-batch/${id}`);

const getBatchById = (id) => client.get(`/api/Batch/get/${id}`);

const deleteBatchCandidate = (data) => {
  //console.log(data);
  return client.post(`/api/Batch/remove-candidate`, data);
}
const getAssessedBatch = (code) => client.get(`/api/Batch/getassessed/${code}`);

const assessorApis = {
  updateProfile,
  getApplications,
  editAssessor,
  getApplicationDetail,
  getSkillReport,
  getCompetencyReport,
  submitAssessedBatch,
  forgotPassword,
  resetPassword,
  submitParticipant,
  uploadImage,
  getCandidates,
  getAllCandidates,
  addCandidate,
  updateCandidate,
  deleteCandidate,
  createBatch,
  getBatches,
  updateBatch,
  deleteBatch,
  getBatchById,
  deleteBatchCandidate,
  getAssessBatch,
  autoSave,
  getAssessedBatch,
};

export default assessorApis;
