const getItem = (itemName) => window.localStorage.getItem(itemName);

const removeItem = (itemName) => window.localStorage.removeItem(itemName);

const setItem = (itemName, itemValue) => window.localStorage.setItem(itemName, itemValue);


const store = {
    getItem,
    setItem,
    removeItem
}

export default store;