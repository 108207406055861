import React, { useEffect, useState } from "react";

import routes from "../../routes";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { PuffLoader } from "react-spinners";
import Wrapper from "../../layouts/Wrapper";
import Loader from "../../components/Loader";
import assessorApis from "../../api/assessor";
import { FaArrowRight } from "react-icons/fa";
import { helpers } from "../../utils/helpers";
import gamesIcon from "../../images/gamesIcon.png";
import styles from "../../styles/AssessorDashboard.module.css";

const AssessorDashboard = () => {

  const navigate = useNavigate();

  const [noOfParticipants, setNoOfParticipants] = useState(1);
  const [participants, setParticipants] = useState([]);

  const [selectBatch, setSelectBatch] = useState();

  const [assessedBatch, setAssessedBatch] = useState();
  const getAssessBatchApi = useApi(assessorApis.getAssessBatch);

  const [activePage, setActivePage] = useState("first");
  const [activeApplication, setActiveApplication] = useState();

  const [applications, setApplications] = useState([]);
  const getApplicationsApi = useApi(assessorApis.getApplications);
  //const [applicationDetails, setApplicationDetails] = useState();
  const getApplicationDetailApi = useApi(assessorApis.getApplicationDetail);

  const submitAssessedBatchApi = useApi(assessorApis.submitAssessedBatch);
  const submitParticipantApi = useApi(assessorApis.submitParticipant);

  const autoSaveApi = useApi(assessorApis.autoSave);

  const [applicationData, setApplicationData] = useState([]);

  useEffect(() => {
    getApplications();
  }, []);

  const getApplications = async () => {
    const res = await getApplicationsApi.request();
    if (res.ok) setApplications(res.data.data);
  };
  
  const handleAutoSave = async (candidateId) => {
    autoSaveApi.request({ ...candidateId, applicationCode: activeApplication.code });
  };

  const handleStartAssessment = async () => {
    const res = await getAssessBatchApi.request({ applicationCode: activeApplication.code, batchId: selectBatch.batchId });
    if (res.ok) {
      const result = await getApplicationDetailApi.request({ applicationCode: activeApplication.code, batchId: selectBatch.batchId });
    }
  }
  
  const handleSubmit = async (data) => {
    const res = await submitAssessedBatchApi.request({ code: data.applicationCode, batchId: data.batchId });
    if (res.ok) helpers.toaster.success("Batch Assessment submitted successfully");
  }
  

  return (
    <Wrapper activePage={routes.AssessorDashboard}>
      <Loader loading={submitAssessedBatchApi.loading} />
      <div className={styles.form}>
        {!activeApplication ? (
          <div>
            <h2 align="center">Dashboard</h2>
            <div className="row m-0 p-0">
              <div className="col-lg-12 m-auto">
                <div className={styles.card1}>
                  <table className="table-bordered">
                    <thead>
                      <td style={{ padding: "15px 0px" }}> <span>Company Name</span> </td>
                      <td> <span>Games</span> </td>
                      <td><span>Date</span></td>
                      <td><span>Time</span> </td>
                      <td><span>Status</span> </td>
                      <td> <span>Action</span> </td>
                    </thead>
                    {applications.length === 0 &&
                      !getApplicationsApi.loading && (
                        <div className="col-lg-4">
                          No Applications available{" "}
                        </div>
                      )}
                    {getApplicationsApi.loading ? (
                      <div className={styles.loader}>
                        <PuffLoader loading={true} />
                      </div>
                    ) : (
                      getApplicationsApi.data.map((item, key) => (
                        <tr key={key}>
                          <td className={styles.left} style={{ padding: "15px 0px" }}>{item.companyName}</td>
                          <td>{item.applicationGames.map((games) => <font>{games.name} <br></br></font>)}</td>
                          <td><font>{item.dateOfEvent}.  <br></br></font></td>
                          <td> <font>{item.timeOfEvent}.  <br></br></font></td>
                          <td> <font>{item.isPlayed === true ? "Assessed" : "Not yet Assessed"}.  <br></br></font></td>
                          <td>
                            <Link to={`${routes.AssessmentBatches}?applicationCode=${item.code}`} style={{ backgroundColor: "#3E3B4E", color: "#fff", padding: "10px 30px", borderRadius: "20px", display: "inline-block"}}>View Batches</Link>
                            {/* <Link to={`${routes.AssessmentCandidates}?applicationCode=${item.code}`} style={{ backgroundColor: "#3E3B4E", color: "#fff", padding: "10px 30px", borderRadius: "20px" }}>View Candidates</Link> */}
                            <Link to="" onClick={() => setActiveApplication(item)} style={{ backgroundColor: "#27E34B", color: "#fff", padding: "10px 30px", borderRadius: "20px", display: "inline-block" }}>Start Assessment</Link>                            
                            {/* <button
                              onClick={() => setActiveApplication(item)}
                              className={styles.start}
                            >
                              {item.noOfParticipant > 0 ? "Start Assessment " : "Start Application"}
                            </button> */}
                          </td>
                        </tr>
                      ))
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : !getApplicationDetailApi.data.length ? (
          <div>
            <h2 align="center">Assessment Process</h2>
            <div className="row m-0 p-0">
              <div className="col-lg-6 m-auto">
                <div className={styles.card1}>
                  <div className="row">
                    <div className="col-lg-12 mb-3">
                      <label>Company Name</label>
                      <span className="form-control">
                        {activeApplication.companyName}
                      </span>
                    </div>
                    <div className="col-lg-5 mb-3">
                      <label>Name of observer</label>
                      <span className="form-control">
                        {activeApplication.assessors.map(list => <h6>{list.firstName} {list.lastName}</h6>)}
                      </span>
                    </div>
                    <div className="col-lg-3 mb-3">
                      <label>Date</label>
                      <span className="form-control">
                          {activeApplication.dateOfEvent}
                      </span>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <label>Time</label>
                      <span className="form-control">
                        {activeApplication.timeOfEvent}
                      </span>
                    </div>
                    <div className="col-lg-12 mt-4">
                        <label>Select Batch to Assess</label>
                        <select className="form-control" onChange={(e) => setSelectBatch({...selectBatch, batchId: e.target.value})}>
                            <option selected disabled>Select a Batch</option> 
                            {activeApplication.batches?.map(list => <option value={list.batchId}>{list.name}</option>)}
                        </select> 
                    </div>
                    <div className="col-lg-12">
                      <div className="text-center mt-3">
                        <button
                            onClick={handleStartAssessment}
                          className={styles.sendCode}
                        >
                          {" "}{getAssessBatchApi.loading
                            ? "PROCESSING..."
                            : "CONTINUE"}{" "}
                          &nbsp;&nbsp;
                          <FaArrowRight />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h2 align="center">Assessment Test </h2>


            {getApplicationDetailApi.data.map((games, key) =>
              <div key={key}>
                <div className="row m-0 p-0">
                  <div className="col-lg-12 m-auto">
                    <div className={styles.card}>
                      <div className="row">
                        <div className="col-lg-2 m-auto">
                          <img src={gamesIcon} width="150px" alt=""></img>
                        </div>
                        <div className="col-lg-6 m-0 p-0">
                          <h4 className={styles.gameTitle}>
                            {games.gameName}
                          </h4>
                          <p className={styles.description}>
                            {games.gameDescription}
                          </p>
                        </div>
                        <div className="col-lg-4 text-center m-auto">
                          <h5 className={styles.gameTitle}>
                            {games.batch.name}<br />
                            {games.batch?.candidates?.length}{" "}
                            Candidate(s)
                          </h5>
                        </div>
                      </div>
                    </div>
                    <br></br>

                    <div hidden={activePage !== "first"}>
                      <Link to="" onClick={() => setActivePage("first")} className={styles.name}>Styles<span className={styles.small}>Styles</span></Link>
                      <Link to="" onClick={() => setActivePage("second")} className={styles.nameActive}>Competencies <span className={styles.small}>Competencies</span></Link>
                      <div className={styles.card2}>
                        {games.skills.map((skill, key) => (
                          <table width={"100%"} key={key} className="mb-5">
                            <thead>
                              <tr><h4><b>{skill.skillName}</b></h4></tr>
                            </thead>
                            <thead>
                              <td width={"40%"}>Question</td>
                              <td width={"40%"}>Options</td>
                              {games.batch?.candidates?.map((candidate, key) => (<td key={key} width={"20%"}>{candidate.name}</td>))}
                            </thead>

                            <tr>
                              <td colSpan={games.batch?.candidates?.length + 2}><hr></hr></td></tr>

                            {skill.statement.map((statement) => (
                              statement.skillStatementOptions.map((item, key) => <>
                                {key === 0 && <thead><td colSpan={0} >{statement.statementTitle}</td></thead>} {/* only show this once  */}
                                <tr className={styles.contents} key={key}>
                                  <td></td>
                                  <td>{item.statementOptionTitle}</td>
                                  {games.batch?.candidates?.map(
                                    (candidate, key) => (
                                      <td key={key}>
                                        <input type="checkbox" style={{ width: 100, height: 20 }}
                                          onChange={(e) => handleAutoSave({
                                            candidateId: candidate.candidateId, applicationSkillId: skill.applicationSkillId,
                                            statementId: statement.statementId,
                                            quadrantId: item.quadrantId,
                                            statementOptionId: item.statementOptionId,
                                            statementOptionValue: e.target.checked
                                          })}
                                        />
                                      </td>
                                    )
                                  )}
                                </tr></>)
                            ))}
                          </table>
                        )
                        )}
                      </div>
                    </div>

                    <div hidden={activePage !== "second"}>
                      <Link to="" onClick={() => setActivePage("first")} className={styles.nameActive}>Styles <span className={styles.small}>Styles</span></Link>
                      <Link to="" onClick={() => setActivePage("second")} className={styles.name}>Competencies <span className={styles.small}>Competencies</span></Link>
                      <div className={styles.card2}>
                        {games.competencies.map((competency, key) => (
                          <table width={"100%"} key={key} className="mb-5">
                            <thead>
                              <tr><h4><b> {competency.competencyName}</b></h4></tr>
                            </thead>
                            <thead>
                              <td width={"40%"}>Question</td>
                              <td width={"40%"}>Options</td>
                              {games.batch?.candidates?.map((candidate, key) => (<td key={key} width={"20%"}>{candidate.name}</td>))}
                            </thead>
                            <tr><td colSpan={games.batch?.candidates?.length + 2}><hr></hr></td></tr>
                            {competency.questionaireStatement.map((questionaireStatement) => (
                              questionaireStatement.questionaireResponseOptions.map((responseOption, key) => <>
                                {key === 0 && <thead><td colSpan={0} >{questionaireStatement.question}</td></thead>} {/* only show this once  */}
                                <tr key={key} className={styles.contents}>
                                  <td></td>
                                  <td>{responseOption.title}</td>
                                  {games.batch?.candidates?.map((candidate, key) => (
                                    <td key={key}>
                                      <input type="checkbox" style={{ width: 100, height: 20 }}
                                        onChange={(e) => handleAutoSave({
                                          candidateId: candidate.candidateId,
                                          questionaireOptionId: responseOption.id,
                                          questionaireOptionValue: e.target.checked,
                                          questionaireId: questionaireStatement.questionaireId,
                                          applicationCompetencyId: competency.applicationCompetencyId,
                                        })}
                                      />
                                    </td>
                                  )
                                  )}
                                </tr></>)
                            ))}
                          </table>
                        )
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            )}

            <div className="row m-0 p-0">
              <div className="col-lg-12 m-auto">
                <div className="text-center mt-3 mb-5">
                      <button onClick={() => handleSubmit({ batchId: getApplicationDetailApi.data[0].batch.batchId, applicationCode: activeApplication.code })} className={styles.sendCode}>
                    {submitAssessedBatchApi.loading ? "SUBMITTING..." : "SUBMIT"}
                      </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default AssessorDashboard;