import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import store from "../../api/store";
import { Link } from 'react-router-dom';
import cadminApis from "../../api/cadmin";
import useApi from "../../hooks/useApi";
import homeBg from "../../images/img1.svg";
import logo from "../../images/LogoParody.svg";
import recruit from "../../images/recruit.svg";
import routes from "../../routes";
import styles from "../../styles/RequestCode.module.css";


const EnterAccess = () => {
    const navigate = useNavigate();
    const [error, setError] = useState("");
    const [token, setToken] = useState();
    const enterAccessCodeApi = useApi(cadminApis.submitAccessCode);
    const [searchParams, setSearchParams] = useSearchParams();

    const code = searchParams.get("accessCode");
    const email = searchParams.get("email");

    const handleSubmit = async (e) => {
        e.preventDefault();
        enterAccessCodeApi.setLoading(true);
        window.localStorage.setItem("accessCode", JSON.stringify({ email, token: code }));
        const res = await enterAccessCodeApi.request({ email, token: code });
        if(res.ok) {
            store.setItem("userData", JSON.stringify(res.data.data));
            return navigate(routes.UpdateProfile);
        }
        
        setError(res.data.responseMessage);
        enterAccessCodeApi.setLoading(false);
    }


    /////////////////////////////////////////////////////////////////////
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div className="row m-0 p-0">
                <div className="col-lg-4 p-0">
                    <div style={{ height: `${screenSize.dynamicHeight}px` }}>
                        <header className={styles.header}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={logo} className={styles.logo} alt="logo"></img>
                                </div>
                                <div className="col-md-6 text-right">
                                    <p className={styles.lang}><Link to="#" className={styles.link}><b>EN</b></Link>&nbsp;&nbsp;&nbsp;&nbsp;NL &nbsp;&nbsp; FR</p>
                                </div>
                            </div>
                        </header>
                        <div className={styles.content}>
                            <h3 align="center" className="mb-5 mt-5 pb-5">Enter access code sent</h3>
                            <div className={styles.errorContainer}>
                                <span className={styles.error}>{error}</span>
                            </div>
                            <div className="text-center mt-5">
                                <div>
                                    <input disabled value={code} type="number" maxLength={6} className={styles.enterCode} onChange={(e) => setToken(e.target.value)} />
                                    <button onClick={handleSubmit} className={styles.sendCode}>{enterAccessCodeApi.loading ? "VERIFYING..." : "PROCEED"}</button>
                                </div>
                            </div>
                        </div>
                        <footer className={styles.footer}>
                            <p className="mt-5 pt-5">© 2022 PARODy platform is powered by Pilipili Games</p>
                        </footer>
                    </div>
                </div>
                <div className="col-lg-8 p-0">
                    <div className={styles.right}>
                        <div>
                            <img src={recruit} alt="logo" className={styles.logo1}></img>
                        </div>
                        <h2 className={styles.subtitle}>Serious Recruitment Games</h2>
                        <div>
                            <img src={homeBg} alt="people playing" className={styles.image1}></img>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default EnterAccess;