import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import routes from "../../routes";
import store from "../../api/store";
import useApi from "../../hooks/useApi";
import schemas from "../../utils/schemas";
import assessorApis from "../../api/assessor";
import { Link } from 'react-router-dom';
import homeBg from "../../images/img1.svg";
import Loader from "../../components/Loader";
import { helpers } from "../../utils/helpers";
import logo from "../../images/LogoParody.svg";
import recruit from "../../images/recruit.svg";
import styles from "../../styles/Login.module.css";
import { Form, SubmitButton, TextField } from "../../components/form";
import client from "../../api/client";
import { Alert } from "bootstrap";

const AssessorForgotPassword = () => {
    const navigate = useNavigate();

    const userForgotPassApi = useApi(assessorApis.forgotPassword);
    const [forgotPass, setForgotPass] = useState([]);

    const [statusMessage, setStatusMessage] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await userForgotPassApi.request(forgotPass);
        if (res.ok) {
            helpers.toaster.success("Request Sent succcessfully");
            setStatusMessage("Request Sent Successfully. Kindly check your mail to reset your password.");
            return statusMessage;
        }
    }


    /////////////////////////////////////////////////////////////////////
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    ////////////////////////////////////////////////////////////////////////
    
    return (
        <div>
            <div className="row m-0 p-0">
                <div className="col-lg-4 p-0">
                    <div style={{ height: `${screenSize.dynamicHeight}px` }}>
                        <header className={styles.header}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={logo} className={styles.logo} alt="logo"></img>
                                </div>
                                <div className="col-md-6 text-right">
                                    <p className={styles.lang}><Link to="#" className={styles.link}><b>EN</b></Link>&nbsp;&nbsp;&nbsp;&nbsp;NL &nbsp;&nbsp; FR</p>
                                </div>
                            </div>
                        </header>
                        <div className={styles.content1}>
                            <h2 className="mb-5 pb-5 mt-5 text-center">Forgot Password?</h2>
                            <h5 style={{color: "green", textAlign: "center", marginTop: "-20px"}}>{statusMessage}</h5>
                            <form method="post" className={styles.form1} onSubmit={handleSubmit}>
                                <input type="email" className="form-control mt-5 mb-5" placeholder="Enter your account email to proceed" required onChange={(e) => setForgotPass({ ...forgotPass, email: e.target.value })}></input>
                                <button type="submit" className={styles.sendCode}>{userForgotPassApi.loading ? "SENDING REQUEST..." : "SUBMIT"}</button>
                            </form>
                            <p style={{ textAlign: "center", marginTop: "50px" }}>Go Back To <Link style={{ color: "#000" }} to="../assessor/login">Login Page</Link></p>
                        </div>
                        
                        <footer className={styles.footer}>
                            <p>© 2022 PARODy platform is powered by Pilipili Games</p>
                        </footer>
                    </div>
                </div>
                <div className="col-lg-8 p-0 text-center">
                    <div className={styles.right}>
                        <div>
                            <img src={recruit} alt="" className={styles.logo1} />
                        </div>
                        <h2 className={styles.subtitle}>Serious Recruitment Games</h2>
                        <div>
                            <img src={homeBg} alt="sideImage" className={styles.image1} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AssessorForgotPassword;