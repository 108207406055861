import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaArrowRight } from 'react-icons/fa';
import { faX } from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from "react-multi-select-component";

import styles from "../../styles/Assessment.module.css";

import routes from "../../routes";
import apis from "../../api/cadmin";
import useApi from "../../hooks/useApi";
import Modal from "../../components/Modal";
import Wrapper from "../../layouts/Wrapper";
import { PuffLoader } from "react-spinners";
import { helpers } from "../../utils/helpers";
import validationSchemas from "../../utils/schemas";
import GameField from "../../components/form/GameField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, SubmitButton, TextField } from "../../components/form";
import FinishAssessmentButton from "../../components/form/FinishAssessmentButton";

const AssessmentPage = () => {    
    const skillApi = useApi(apis.getSkills);
    const talentApi = useApi(apis.getTalents);
    const gamesApi = useApi(apis.getGames);
    const filteredGamesApi = useApi(apis.getFilteredGames);

    const [modalVisible, setModalVisible] = React.useState(false);
    const createApplicationApi = useApi(apis.createApplication);

    const [error, setError] = useState({});
    const [skillOptions, setSkillOptions] = useState([]);
    const [compentencyOptions, setCompetencyOptions] = useState([]);

    const [selectedSkillOptions, setSelectedSkillOptions] = useState([]);
    const [selectedCompentencyOptions, setSeletedCompetencyOptions] = useState([]);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if(!isFiltered()) return filteredGamesApi.resetData();

        const filters = {
            skill: selectedSkillOptions.map(item => item.value),
            competency: selectedCompentencyOptions.map(item => item.value)
        };

        handleFilters(filters);
    }, [selectedSkillOptions, selectedCompentencyOptions]);
    
    
    const getData = async () => {
        const res = await Promise.all([
            skillApi.request(),
            talentApi.request(),
            gamesApi.request()
        ]);
        
        const skills = res[0].data.data.map((item) => helpers.renameKeys(item, {'name': "label", 'id': 'value'}));
        const competencies = res[1].data.data.map((item) => helpers.renameKeys(item, {'name': "label", 'id': 'value'}));
        setSkillOptions(skills);
        setCompetencyOptions(competencies);
    }

    const handleSubmit = async (data) => {
        const res = await createApplicationApi.request(data);
        if(!res.ok) {
            setModalVisible(false);
            return setError({ message: "An error occured while creating an assessment" });
        };
        
        setModalVisible(false);
        return helpers.toaster.success("Successfully Submitted");
    }

    const handlePreSubmit = (data) => {
        setError("");
        if(data?.games?.length > 0) return setModalVisible(true);

        setError({ message: "No game selected. please select a game." });
    }

    const isFiltered = () => selectedSkillOptions.length + selectedCompentencyOptions.length > 0;

    const handleFilters = async (filters) => {
        try {
            const res = await filteredGamesApi.request(filters);
            console.log(res);
            console.log(filters);
        } catch (error) {
            console.log(error);
        }
    }

    //const sorting = <h4>{gameList.sort((a, b) => (b.competency.length - a.competency.length)).map((games) => <p>{games.name}{games.competency.length}</p>)}</h4>;

    return (
        <Wrapper activePage={routes.AssessmentPage}>
            <Form
                validationSchema={validationSchemas.assessmentSchema}
                initialValues={{
                    games: [],
                    dateOfEvent: "",
                    timeOfEvent: "",
                }}
                onSubmit={handleSubmit}
            >
                
                <div className={styles.form}>
                    <div>
                        <h4>Filter by:</h4>
                        <div className={styles.filters}>
                            <div>
                                <label className={styles.label}>Styles</label>
                                <MultiSelect
                                    isLoading={skillApi.loading}
                                    shouldToggleOnHover
                                    options={skillOptions}
                                    className={styles.filter}
                                    value={selectedSkillOptions}
                                    labelledBy={"Filter by Styles"}
                                    onChange={(items) => setSelectedSkillOptions(items)}
                                />
                            </div>
                            <div>
                                <label className={styles.label}>Competencies</label>
                                <MultiSelect
                                    isLoading={talentApi.loading}
                                    shouldToggleOnHover
                                    className={styles.filter}
                                    options={compentencyOptions}
                                    labelledBy={"Filter by Competencies"}
                                    value={selectedCompentencyOptions}
                                    onChange={(items) => setSeletedCompetencyOptions(items)}
                                />
                            </div>
                            {isFiltered() && (
                                <div className={styles.clear} onClick={() => {
                                    setSelectedSkillOptions([]);
                                    setSeletedCompetencyOptions([]);
                                }}>
                                    <label className={styles.label}>Clear All</label>
                                    <FontAwesomeIcon title="Clear" icon={faX} />
                                </div>
                            )}
                        </div>
                    </div>
                    <h2 className={styles.title}>{gamesApi.loading ? "Loading" : "Showing"} {isFiltered() ? "Filtered" : "All"} Games</h2>
                    <div className="mb-5 pb-1 text-end">
                        {error.message && <p className={styles.errorMessage}>{error.message}</p>}
                        <FinishAssessmentButton onClick={handlePreSubmit} styles={styles.next} title="FINISH" icon={<FaArrowRight className={styles.faArrowRight} />} />
                    </div>
                    <p className={styles.subtitle}>{isFiltered() ? "Below are the games recommended for your selected filters" : "Select games to proceed"}</p>
                    {gamesApi.loading || filteredGamesApi.loading ?
                        <div className={styles.loader}>
                            <p>Loading...</p>
                            <PuffLoader loading={true} />
                        </div>
                    : 
                        <>
                            {(isFiltered() ? filteredGamesApi.data : gamesApi.data).map((game) => <GameField key={game.id} game={game} name={"games"} />)}
                            <div>
                                {isFiltered() && filteredGamesApi.data.length === 0 && <div className={styles.noGames}>No games found with the selected filters</div>}
                                {gamesApi.data.length === 0 && <div className={styles.noGames}>No games available</div>}
                            </div>
                            <div className="mt-5 mb-5">
                                {error.message && <p className={styles.errorMessage}>{error.message}</p>}
                                <FinishAssessmentButton onClick={handlePreSubmit} styles={styles.next} title="FINISH" icon={<FaArrowRight className={styles.faArrowRight} />} />
                            </div>
                        </>
                    }
                </div>
            
                <Modal show={modalVisible} onHide={() => setModalVisible(false)} title="Recruit Pursuit Request Form">
                    <form method="post" onSubmit="return false" className={styles.form1}>
                        <span>Select date and time of event to complete your application</span>
                        <div className="row mt-5">
                            <div className="col-lg-6">
                                <label>Date of event</label>
                                <TextField type="date" className="form-control" name="dateOfEvent" />
                            </div>
                            <div className="col-lg-6">
                                <label>Time of event</label>
                                <TextField type="time" className="form-control" name="timeOfEvent" />
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <SubmitButton className={styles.sendCode} title={createApplicationApi.loading ? "Submitting..." : "Submit"} />
                        </div>
                    </form>
                </Modal>
            </Form>
        </Wrapper>
    )
};

export default AssessmentPage;