export default Object.freeze({
    LandingPage: "/",
    ForgotPassword: "/admin/forgot-password",
    ResetPassword: "/admin/reset-password",
    AssessorForgotPassword: "/assessor/forgot-password",
    AssessorResetPassword: "/assessor/reset-password",
    CompanyForgotPassword: "/company-admin/forgot-password",
    CompanyResetPassword: "/company-admin/reset-password",
    CompanyAdminLogin: "/company-admin/login",
    AssessorLogin: "/assessor/login",
    SuperAdminLogin: "/admin/login",
    GamesPage: "/admin/games",
    ReportPage: "/admin/report",
    CompanyPage: "/admin/companys",
    CompanyUserPage: "/admin/company-users",
    BootstrapModal: "/admin/modal",
    DashboardPage: "/admin/dashboard",
    AssessorsPage: "/admin/assessors",
    TalentStylePage: "/admin/talent-style",
    UserRequestPage: "/admin/user-request",
    ApplicationPage: "/admin/applications",
    TalentStyleAttribute: "/admin/talent-style-attributes",
    RegisterAssessor: "/assessor/register",
    EnterAccess: "/company-admin/enter-code",
    RequestCode: "/company-admin/request-access",
    RequestStatus: "/company-admin/request-status",
    UpdateProfile: "/company-admin/update-profile",
    CreateUser: "/company-admin/create-new-user",
    CompanyApplications: "/company-admin/company-applications",

    LoginPage: "/auth/login",
    QuatationPage: "/quatations/",
    CompanyReport: "/company-admin/report",
    AssessorDashboard: "/assessor/dashboard",
    AssessmentProcess: "/assessor/assessment",
    AssessmentTestPage: "/assessor/assessment-test",
    UserProfile: "/company-admin/user-profile",
    AssessmentPage: "/company-admin/assessment",
    AssessorUpdateProfile: "/assessor/update-profile",
    AssessorProfile: "/assessor/user-profile",
    AssessorReport: "/assessor/report",
    AssessmentBatches: "/assessor/batches",
    AssessmentCandidates: "/assessor/candidates",
});