import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFilePicker } from 'use-file-picker';
import { ToastContainer } from 'react-toastify';

import routes from "../../routes";
import { Link } from 'react-router-dom';
import useApi from "../../hooks/useApi";
import cadminApis from "../../api/cadmin";
import schemas from "../../utils/schemas";
import { FaCamera } from 'react-icons/fa';
import homeBg from "../../images/img1.svg";
import Loader from "../../components/Loader";
import Form from "../../components/form/Form";
import { helpers } from "../../utils/helpers";
import recruit from "../../images/recruit.svg";
import logo from "../../images/LogoParody.svg";
import TextField from "../../components/form/TextField";
import StepButton from "../../components/form/StepButton";
import styles from "../../styles/UpdateProfile.module.css";
import SubmitButton from "../../components/form/SubmitButton";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const UpdateProfile = () => {
    const navigate = useNavigate();

    const [openFileSelector, { filesContent }] = useFilePicker({
        readAs: 'DataURL',
        accept: 'image/*',
        multiple: false
    });

    const [activePage, setActivePage] = useState("first");
    const updateProfileApi = useApi(cadminApis.updateProfile);
    const accessData = JSON.parse(window.localStorage.getItem("accessCode"));
    const userData = JSON.parse(window.localStorage.getItem("userData"));    

    const handleSubmit = async (data) => {

        const res = await updateProfileApi.request({...data, code: accessData.token, image: filesContent });
        if(!res.ok) return;
        
        setTimeout(() => navigate(routes.CompanyAdminLogin), 5000);

        helpers.toaster.success("Profile Successfully Created, Redirecting....");
    }

    /////////////////////////////////////////////////////////////////////
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    ////////////////////////////////////////////////////////////////////////

    return (
        <>
            <div className="row m-0 p-0">
                <div className="col-lg-4 p-0">
                    <div style={{ height: `${screenSize.dynamicHeight}px` }}>
                        <header className={styles.header}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={logo} className={styles.logo} alt="logo"></img>
                                </div>
                                <div className="col-md-6 text-right">
                                    <p className={styles.lang}><Link to="#" className={styles.link}><b>EN</b></Link>&nbsp;&nbsp;&nbsp;&nbsp;NL &nbsp;&nbsp; FR</p>
                                </div>
                            </div>
                        </header>
                        <Form
                            validationSchema={schemas.profileSchema}
                            initialValues={{
                                Surname: "",
                                LastName: userData.lastName,
                                Password: "",
                                cpassword: "",
                                FirstName: userData.firstName,
                                CompanyName: "",
                                CompanyPhoneNumber: "",
                                CompanyEmail: accessData.email,
                            }}
                            onSubmit={handleSubmit}
                        >
                            <div id="step1" className={styles.content} hidden={activePage !== "first"}>
                                <div>
                                    <span className={styles.stepActive}></span><hr></hr>
                                    <span className={styles.step}></span><hr></hr>
                                    <span className={styles.step}></span>
                                </div>
                                <h3 className="mt-3 mb-5 pb-5">Create profile</h3>
                                <div className={styles.form}>
                                    <TextField name={"CompanyEmail"} disabled />
                                    <TextField name={"Password"} placeholder="Password" secureEntry autoFocus />
                                    <TextField name={"cpassword"} placeholder="Confirm Password" secureEntry />
                                    <StepButton onNext={() => setActivePage("second")} label={"CONTINUE"} fields={["Password", "cpassword", "CompanyEmail"]} />
                                </div>
                            </div>

                            <div id="step2" className={styles.content1} hidden={activePage !== "second"} >
                                <div>
                                    <span className={styles.step}></span><hr></hr>
                                    <span className={styles.stepActive}></span><hr></hr>
                                    <span className={styles.step}></span>
                                </div>
                                <h3>Create profile</h3>
                                <div className={styles.form}>
                                    <TextField name={"CompanyName"} label="Company Name" />
                                    <TextField name={"FirstName"} label="First Name" />
                                    <TextField name={"LastName"} label="Last Name"  />
                                    <TextField name={"CompanyPhoneNumber"} label="Company Phone" />
                                    <StepButton onNext={() => setActivePage("third")} label={"CONTINUE"} fields={["CompanyName", "FirstName", "LastName", "CompanyPhoneNumber"]} />
                                </div>
                            </div>

                            <div id="step3" className={styles.content2} hidden={activePage !== "third"} >
                                <div>
                                    <span className={styles.step}></span><hr></hr>
                                    <span className={styles.step}></span><hr></hr>
                                    <span className={styles.stepActive}></span>
                                </div>
                                <h3 className="mt-5 mb-5">Upload Company logo</h3>
                                <div className={styles.form}>
                                    {
                                        filesContent.length > 0 ? <img style={{ marginLeft: 110, marginBottom: 40 }} src={filesContent[0].content} width={100} alt="" /> :
                                    <div className={styles.upload} onClick={() => openFileSelector()}>
                                            <FaCamera className={styles.camera} /> 
                                    </div>
                                    }
                                    <div className="text-center">
                                        <SubmitButton title={updateProfileApi.loading ? "SUBMITTING..." : "COMPLETE"} />
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <footer className={styles.footer}>
                            <p>© 2022 PARODy platform is powered by Pilipili Games</p>
                        </footer>
                    </div>
                </div>
                <div className="col-lg-8 p-0">
                    <div className={styles.right}>
                        <div>
                            <img src={recruit} alt="" className={styles.logo1}></img>
                        </div>
                        <h2 className={styles.subtitle}>Serious Recruitment Games</h2>
                        <div>
                            <img src={homeBg} alt="" className={styles.image1}></img>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
};

export default UpdateProfile;