import React from 'react'
import { useFormikContext } from "formik";

export default function StepButton({ className, ArrowRightComponent, onNext, fields, label }) {
  const { errors, setFieldTouched } = useFormikContext();

  const handleNext = (e) => {
      fields.forEach(field => setFieldTouched(field));
      const isValid = !Object.keys(errors).some(error => fields.includes(error));
      if(isValid) onNext(e);
  }

  return (
        <div className="text-center">
            <button onClick={handleNext} className={className ? className : "btn btn-dark mt-4 px-5 py-5 pt-3 pb-3"}>{label} {ArrowRightComponent}</button>
        </div>
    )
}
