import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import routes from "../routes";
import store from "../api/store";
import useApi from "../hooks/useApi";
import schemas from "../utils/schemas";
import commonApis from "../api/common";
import { Link } from 'react-router-dom';
import homeBg from "../images/img1.svg";
import Loader from "../components/Loader";
import { helpers } from "../utils/helpers";
import logo from "../images/LogoParody.svg";
import recruit from "../images/recruit.svg";
import styles from "../styles/Login.module.css";
import { Form, SubmitButton, TextField } from "../components/form";
import client from "../api/client";

const LoginPage = () => {
    const navigate = useNavigate();
    const [userType, setUserType] = useState();
    const loginApi = useApi(commonApis.login)

    useEffect(() => {
        const url = window.location.href.split("/");
        const userType = url.includes('admin') ? "admin" : url.includes('company-admin') ? "company-admin" : "assessor"
        setUserType(userType);
        localStorage.setItem("ucat", userType);
    }, []);

    const handleSubmit = async (data) => {
        const res = await loginApi.request({ ...data, userType });

        if(!res.ok) return;
        
        store.setItem("user", JSON.stringify(res.data.data));
        const company = { code: res.data.data.companyCode, email: res.data.data.companyEmail, logo: res.data.data.logo, name: res.data.data.companyName }
        store.setItem("companyInfo", JSON.stringify(company));

        client.setHeader("Authorization", `Bearer ${res?.data?.data?.jwtToken}`);

        return navigate(userType === "admin" ? routes.DashboardPage : userType === "assessor" ? routes.AssessorDashboard : routes.AssessmentPage);
    }


    /////////////////////////////////////////////////////////////////////
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight
    });
    const setDimension = () => {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight
        })
    }

    useEffect(() => {
        window.addEventListener('resize', setDimension);

        return (() => {
            window.removeEventListener('resize', setDimension);
        })
    }, [screenSize])

    ////////////////////////////////////////////////////////////////////////
    
    return (
        <div>
            <Loader loading={loginApi.loading} />
            <div className="row m-0 p-0">
                <div className="col-lg-4 p-0">
                    <div style={{ height: `${screenSize.dynamicHeight}px` }}>
                        <header className={styles.header}>
                            <div className="row">
                                <div className="col-md-6">
                                    <img src={logo} className={styles.logo} alt="logo"></img>
                                </div>
                                <div className="col-md-6 text-right">
                                    <p className={styles.lang}><Link to="#" className={styles.link}><b>EN</b></Link>&nbsp;&nbsp;&nbsp;&nbsp;NL &nbsp;&nbsp; FR</p>
                                </div>
                            </div>
                        </header>
                        <div className={styles.content}>
                            <h2 className="mb-5 pb-3">Login</h2>
                            <p className={styles.error}>{loginApi.error}</p>
                            <Form
                                validationSchema={schemas.loginSchema}
                                initialValues={helpers.getInitialValuesFromSchema(
                                    schemas.loginSchema
                                )}
                                onSubmit={handleSubmit}
                            >
                                <div className={styles.form}>
                                    <TextField name={"email"} label={"Email"} />
                                    <TextField name={"password"} secureEntry label={"Password"} />
                                    <div className="text-center mt-5">
                                        <SubmitButton title={!loginApi.loading ? "LOGIN" : "LOGGING IN..."} />
                                    </div>
                                </div>
                            </Form>
                            <h6 style={{textAlign: "center", marginTop: "50px"}}><Link style={{color: "#000"}} to={`../${userType}/forgot-password`}>Forgot Password?</Link></h6>
                        </div>
                        
                        <footer className={styles.footer}>
                            <p>© 2022 PARODy platform is powered by Pilipili Games</p>
                        </footer>
                    </div>
                </div>
                <div className="col-lg-8 p-0 text-center">
                    <div className={styles.right}>
                        <div>
                            <img src={recruit} alt="" className={styles.logo1} />
                        </div>
                        <h2 className={styles.subtitle}>Serious Recruitment Games</h2>
                        <div>
                            <img src={homeBg} alt="sideImage" className={styles.image1} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default LoginPage;