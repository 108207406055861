import React, { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import adminApis from "../../api/admin";
import store from "../../api/store";
import Modal from "../../components/Modal";
import { PuffLoader } from "react-spinners";
import Wrapper from "../../layouts/Wrapper";
import { helpers } from "../../utils/helpers";
import styles from "../../styles/UserRequest.module.css";
import routes from "../../routes";
import { Dropdown } from "react-bootstrap";
import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

const UserRequestPage = () => {

    store.setItem("activeTab", JSON.stringify("first"));
    const [randNumber, setRandNumber] = useState();
    const [success, setSuccess] = useState(false);
    const [modalShow, setModalVisible] = useState(false);
    const [activeRequest, setActiveRequest] = useState();
    const submitRequestsTokenApi = useApi(adminApis.submitUserRequestsToken);

    ///////////////////////////////////////////////////////////////////
    const getUserRequestsApi = useApi(adminApis.getUserRequests);
    const [userRequests, setUserRequests] = useState([]);

    useEffect(() => {
        getUserRequests();
    }, []);

    const getUserRequests = async () => {
        const res = await getUserRequestsApi.request();
        if (res.ok) setUserRequests(res.data.data);
    }
    ///////////////////////////////////////////////////////////////////
    
    const handleSubmit = async (e) => {
        const res = await submitRequestsTokenApi.request({ email: activeRequest.email, token: randNumber.toString() });
        if(!res.ok) {
            setSuccess(true);
            helpers.toaster.success("Code succcessfully sent");
            window.location.reload(false);
        }
    }

    const [userRequestToDelete, setUserRequestToDelete] = useState();
    const deleteUserRequestApi = useApi(adminApis.deleteUserRequest);

    const handleUserRequestDelete = async (email) => {
        setUserRequestToDelete(email);
        const del = window.confirm("Are you sure you wants to delete this UserRequest?");
        if (del) {
            const res = await deleteUserRequestApi.request(email);
            if (res.ok) {
                helpers.toaster.success("UserRequest Successfully Deleted");
                window.location.reload(false);
            }

            // window.location.reload(false);
        }
        setUserRequestToDelete(null);
    }

    return (
        <Wrapper activePage={routes.UserRequestPage}>
            <h2 align="center">User Request</h2>
            <div className="row m-0 p-0">
                <div className="col-lg-8 m-auto">
                    <div className={styles.card1}>
                        <table>
                            <thead>
                                <td>Email Address</td>
                                <td>Full Name</td>
                                <td>Action</td>
                            </thead>
                            <tbody>
                                {userRequests.length === 0 && !getUserRequestsApi.loading && <tr>No user request available </tr>}
                                {getUserRequestsApi.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                    userRequests.map((item, key) => <tr key={key}>
                                        <td className={styles.left}><font>{item.email}</font></td>
                                        <td className={styles.left}><label>{item.firstName} {item.lastName}</label></td>
                                        <td className={styles.left}><button onClick={() => {
                                            setActiveRequest(item);
                                            setRandNumber(helpers.getRandNumber());
                                            setModalVisible(true)
                                        }} className={styles.success}> GENERATE CODE</button>
                                            <button type="submit" onClick={() => handleUserRequestDelete(item.email)} className={styles.danger}> {userRequestToDelete === item.code && deleteUserRequestApi.loading ? "DELETING..." : "DELETE"} </button></td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                        <Modal  
                            title="Send Code"
                            show={modalShow}
                            onHide={() => {
                                setSuccess(true);
                                setModalVisible(false);
                            }}
                        >
                            {
                                success ? (
                                    <div>
                                        <h4><b>Successfully Sent</b></h4>
                                        <p className="mb-5"> A 6 digit access code was successfully sent <br></br>to <b>{activeRequest?.email}</b> </p>
                                    </div>
                                ):
                            <>
                                <h4><b>Send Access code</b></h4>
                                <p className="mb-5"> A 6 digit access code has been generated <br></br>for {activeRequest?.email} </p>
                                <h3 className="mb-5"><font id="generatedCode" className={styles.generatedCode}> {randNumber} </font></h3>
                                <button onClick={handleSubmit} disabled={submitRequestsTokenApi.loading} className={styles.sendCode}>{submitRequestsTokenApi.loading ? "SENDING..." : "SEND CODE"}</button>
                            </>
                            }
                        </Modal>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};

export default UserRequestPage;