import React, { useEffect, useState } from "react";

import useApi from "../../hooks/useApi";
import store from "../../api/store";
import adminApis from "../../api/admin";
import Modal from "../../components/Modal";
import { PuffLoader } from "react-spinners";
import Wrapper from "../../layouts/Wrapper";
import { helpers } from "../../utils/helpers";
import { FaEdit, FaTrash } from 'react-icons/fa';
import styles from "../../styles/Request.module.css";
import routes from "../../routes";

const CompanyUserPage = () => {

    store.setItem("activeTab", JSON.stringify("first"));

    const [companyUserToDelete, setCompanyUserToDelete] = useState();
    const deleteCompanyUserApi = useApi(adminApis.deleteCompanyUser);

    const getCompanyApi = useApi(adminApis.getCompany);
    const getCompanyUserApi = useApi(adminApis.getCompanyUser);
    const [company, setCompany] = useState([]);

    useEffect(() => {
        getCompany();
    }, []);

    const getCompany = async () => {
        const res = await getCompanyApi.request();
        if (res.ok) setCompany(res.data.data);
    }

    useEffect(() => {
        getCompanyUser();
    }, []);

    const getCompanyUser = async () => {
        const res = await getCompanyUserApi.request();
    }

    const handleUserDelete = async (email) => {
        const del = window.confirm("Are you sure you wants to delete this Company User?");
        if (del) {
            const res = await deleteCompanyUserApi.request(email);
            if (res.ok) {
                helpers.toaster.success("Company User Successfully Deleted");
                window.location.reload(false);
            }
            if (!res.ok) {
                helpers.toaster.error(res.data.responseMessage);
            }
        }
    }

    return (
        <Wrapper activePage={routes.CompanyUserPage}>
            <h2 align="center" className="mt-5">Company Users</h2>
            <div className="row m-0 p-0">
                <div className="col-lg-10 m-auto">
                    <div className={styles.card1}>
                        <table>
                            <tr style={{ borderBottom: "1px solid grey" }}>
                                <td className={styles.left}><span>Name</span></td>
                                <td className={styles.left}><span>Email Address</span></td>
                                <td className={styles.left}><span>Company Name</span></td>
                                <td className={styles.left}><span>Action</span></td>
                            </tr>
                            {getCompanyUserApi.data.length === 0 && !getCompanyUserApi.data.loading && <tr>No Company Users available </tr>}
                            {getCompanyUserApi.data.loading ? <tr className={styles.loader}><PuffLoader loading={true} /></tr> :
                                getCompanyUserApi.data.map((users, key) => <tr key={key} style={{ borderBottom: "1px solid grey" }}>
                                    <td className={styles.left}><label>{users.firstName} {users.lastName}</label></td>
                                    <td className={styles.left}><label>{users.email}</label></td>
                                    <td className={styles.left}><label>{users.companyName}</label></td>
                                    <td className={styles.left}>
                                        <button type="submit" onClick={() => handleUserDelete(users.email)} className={styles.danger}> <FaTrash /> {companyUserToDelete === users.email && deleteCompanyUserApi.loading ? "Deleting.." : "Delete"} </button>
                                    </td>
                                </tr>)
                            }
                        </table>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};

export default CompanyUserPage;